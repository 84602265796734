import { Speeds as ConnectorSpeed } from '@electro/consumersite/generated/graphql'

interface getMarkerIconPathArgs {
  icon: `${ConnectorSpeed}` | string
}

export enum MarkerNamesEnum {
  ACTIVE = 'active',
  EJN_ACTIVE = 'active-ejn',

  EJN_SLOW = 'ejn-slow',
  EJN_SLOW_AVAILABLE = 'ejn-slow-available',
  EJN_SLOW_OCCUPIED = 'ejn-slow-occupied',
  EJN_SLOW_OUT_OF_ORDER = 'ejn-slow-out-of-order',

  EJN_FAST = 'ejn-fast',
  EJN_FAST_AVAILABLE = 'ejn-fast-available',
  EJN_FAST_OCCUPIED = 'ejn-fast-occupied',
  EJN_FAST_OUT_OF_ORDER = 'ejn-fast-out-of-order',

  EJN_RAPID = 'ejn-rapid',
  EJN_RAPID_AVAILABLE = 'ejn-rapid-available',
  EJN_RAPID_OCCUPIED = 'ejn-rapid-occupied',
  EJN_RAPID_OUT_OF_ORDER = 'ejn-rapid-out-of-order',

  EJN_ULTRA_RAPID = 'ejn-ultra-rapid',
  EJN_ULTRA_RAPID_AVAILABLE = 'ejn-ultra-rapid-available',
  EJN_ULTRA_RAPID_OCCUPIED = 'ejn-ultra-rapid-occupied',
  EJN_ULTRA_RAPID_OUT_OF_ORDER = 'ejn-ultra-rapid-out-of-order',

  ROUTE_WAYPOINT = 'waypoint',
  ROUTE_DESTINATION = 'destination',
  ROUTE_CHARGE_STOP = 'charge-stop',
}
export type MarkerNames = `${MarkerNamesEnum}`

const {
  ACTIVE,
  EJN_ACTIVE,

  EJN_SLOW,
  EJN_SLOW_AVAILABLE,
  EJN_SLOW_OCCUPIED,
  EJN_SLOW_OUT_OF_ORDER,

  EJN_FAST,
  EJN_FAST_AVAILABLE,
  EJN_FAST_OCCUPIED,
  EJN_FAST_OUT_OF_ORDER,

  EJN_RAPID,
  EJN_RAPID_AVAILABLE,
  EJN_RAPID_OCCUPIED,
  EJN_RAPID_OUT_OF_ORDER,

  EJN_ULTRA_RAPID,
  EJN_ULTRA_RAPID_AVAILABLE,
  EJN_ULTRA_RAPID_OCCUPIED,
  EJN_ULTRA_RAPID_OUT_OF_ORDER,

  ROUTE_WAYPOINT,
  ROUTE_DESTINATION,
  ROUTE_CHARGE_STOP,
} = MarkerNamesEnum

export const ICON_URL = {
  [EJN_ACTIVE]: '/images/markers/active-ejn.svg',
  [ACTIVE]: '/images/markers/active.svg',

  [EJN_SLOW]: '/images/markers/ejn-slow.svg',
  [EJN_SLOW_AVAILABLE]: '/images/markers/ejn-slow-available.svg',
  [EJN_SLOW_OCCUPIED]: '/images/markers/ejn-slow-occupied.svg',
  [EJN_SLOW_OUT_OF_ORDER]: '/images/markers/ejn-slow-out-of-order.svg',

  [EJN_FAST]: '/images/markers/ejn-fast.svg',
  [EJN_FAST_AVAILABLE]: '/images/markers/ejn-fast-available.svg',
  [EJN_FAST_OCCUPIED]: '/images/markers/ejn-fast-occupied.svg',
  [EJN_FAST_OUT_OF_ORDER]: '/images/markers/ejn-fast-out-of-order.svg',

  [EJN_RAPID]: '/images/markers/ejn-rapid.svg',
  [EJN_RAPID_AVAILABLE]: '/images/markers/ejn-rapid-available.svg',
  [EJN_RAPID_OCCUPIED]: '/images/markers/ejn-rapid-occupied.svg',
  [EJN_RAPID_OUT_OF_ORDER]: '/images/markers/ejn-rapid-out-of-order.svg',

  [EJN_ULTRA_RAPID]: '/images/markers/ejn-ultra-rapid.svg',
  [EJN_ULTRA_RAPID_AVAILABLE]: '/images/markers/ejn-ultra-rapid-available.svg',
  [EJN_ULTRA_RAPID_OCCUPIED]: '/images/markers/ejn-ultra-rapid-occupied.svg',
  [EJN_ULTRA_RAPID_OUT_OF_ORDER]: '/images/markers/ejn-ultra-rapid-out-of-order.svg',

  [ROUTE_WAYPOINT]: '/images/markers/route-waypoint.png',
  [ROUTE_DESTINATION]: '/images/markers/route-destination.png',
  [ROUTE_CHARGE_STOP]: '/images/markers/route-charge-stop.svg',
}

export default function getMarkerIconPath({ icon }: getMarkerIconPathArgs): string {
  const sharedIcons = {
    [MarkerNamesEnum.ACTIVE]: ICON_URL[ACTIVE],
    [MarkerNamesEnum.ROUTE_CHARGE_STOP]: ICON_URL[ROUTE_CHARGE_STOP],
    [MarkerNamesEnum.ROUTE_DESTINATION]: ICON_URL[ROUTE_DESTINATION],
    [MarkerNamesEnum.ROUTE_WAYPOINT]: ICON_URL[ROUTE_WAYPOINT],
  }

  const iconMap = {
    ...sharedIcons,
    [ConnectorSpeed.Slow]: ICON_URL[EJN_SLOW],
    [ConnectorSpeed.Fast]: ICON_URL[EJN_FAST],
    [ConnectorSpeed.Rapid]: ICON_URL[EJN_RAPID],
    [ConnectorSpeed.Ultra]: ICON_URL[EJN_ULTRA_RAPID],
  }

  return iconMap[icon] || ICON_URL[EJN_SLOW]
}
