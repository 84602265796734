import { useMemo } from 'react'
import { ChevronDownIcon, BoltIcon, MapPinIcon } from '@heroicons/react/24/solid'

import {
  BatteryIcon,
  RouteLineIcon,
  RoutePlannerIcon,
  StopClockIcon,
} from '@electro/consumersite/src/icons'
import durationFromSeconds from '@electro/shared/utils/durationFromSeconds'
import getMilesFromMeters from '@electro/shared/utils/getMilesFromMeters'
import { Button, Typography } from '@electro/shared-ui-components'
import { RoutePathsData, Route } from '@electro/consumersite/src/components/Map/types'
import getRouteSummaryTitles, {
  RouteTitleEnums,
  RouteTitles,
} from '@electro/consumersite/src/components/Map/helpers/getRouteSummaryTitles'
import getTotalRouteDuration from '@electro/consumersite/src/components/Map/helpers/getTotalRouteDuration'
import { tw } from '@electro/shared/utils/tailwind-merge'
import useTranslation from 'next-translate/useTranslation'
import { Locales } from '@electro/shared/types/locales'
import { useRouter } from 'next/router'
import {
  formatDistanceToLocalisedValue,
  formatDurationToLocalisedValue,
} from '@electro/shared/utils/formatters'
import { GTM } from '@electro/consumersite/src/utils/event-triggers'

interface RouteOptionProps {
  route: Route
  /** @onExpand callback for the click to expand the option. */
  onExpand: () => void
  /** @onSelect callback for when a given route is selected. */
  onSelect: () => void
  /** @expanded flag to show hide route options details */
  expanded: boolean
  /** @routes  */
  routes: RoutePathsData
}

const styles = {
  root: 'pb-2 pt-2 border-b border-b-secondary w-full',
  toggle: {
    button: {
      root: 'w-full relative',
      expanded: 'cursor-default',
      minimised: 'group hover:text-tertiary',
      text: 'flex-1 flex items-start justify-between w-full group-hover:text-tertiary',
    },
    icon: 'w-8 h-8 absolute top-0 right-0 text-white',
    title: {
      root: 'w-full text-left mb-0.5  flex items-center gap-1.5',
      minimised: 'group-hover:text-tertiary',
      icon: tw(
        'h-7 w-7 p-1 bg-tertiary-shade rounded-full overflow-visible',
        'group-hover:ring-1 bg-base group-hover:bg-secondary-light group-hover:ring-tertiary',
      ),
    },
  },
  details: {
    root: 'pb-4 pt-1 relative',
    item: 'flex w-full items-center mr-2',
    icon: 'h-6 w-6 mr-2 flex-shrink-0 text-white',
    button: 'absolute bottom-2 right-0',
  },
}

export const SelectRouteOption = ({
  route,
  onExpand,
  expanded,
  onSelect,
  routes,
}: RouteOptionProps) => {
  const { t } = useTranslation('common')
  const router = useRouter()
  const journeyDuration = useMemo(() => {
    const duration = getTotalRouteDuration(route)
    return {
      hours: formatDurationToLocalisedValue({
        duration: duration.hours,
        locale: router.locale,
        unit: 'hour',
      }),
      minutes: formatDurationToLocalisedValue({
        duration: duration.minutes,
        locale: router.locale,
        unit: 'minute',
      }),
    }
  }, [route, router.locale])

  const chargeDuration: { hours: string; minutes: string } = useMemo(() => {
    const duration = durationFromSeconds(route?.summary?.totalChargeDurationSeconds)
    return {
      hours: formatDurationToLocalisedValue({
        duration: duration?.hours,
        locale: router.locale,
        unit: 'hour',
      }),
      minutes: formatDurationToLocalisedValue({
        duration: duration?.minutes,
        locale: router.locale,
        unit: 'minute',
      }),
    }
  }, [route?.summary?.totalChargeDurationSeconds, router.locale])

  const localisedTotalDistanceTraveled = useMemo((): string => {
    let distance
    if (router.locale === Locales.EN) {
      distance = Math.floor(getMilesFromMeters(route?.summary?.totalDistMeters))
    } else {
      distance = Math.floor(route.summary.totalDistMeters / 1000)
    }
    return formatDistanceToLocalisedValue({
      distance,
      locale: router.locale,
      unit: router.locale === Locales.EN ? 'mile' : 'kilometer',
    })
  }, [route?.summary, router.locale])

  const routeSummaryTitle = useMemo(() => getRouteSummaryTitles({ route, routes }), [route, routes])

  const mapRouteSummaryTitleToIcon = (title: RouteTitles) => {
    const iconMap = {
      [RouteTitleEnums.FASTEST_ROUTE]: <StopClockIcon className={styles.toggle.title.icon} />,
      [RouteTitleEnums.SHORTEST_ROUTE]: <RouteLineIcon className={styles.toggle.title.icon} />,
      [RouteTitleEnums.SHORTEST_CHARGE]: <BatteryIcon className={styles.toggle.title.icon} />,
    }
    return iconMap[title] || null
  }

  const sendRouteDetailsAnalytics = () => {
    if (!route?.summary) return
    const summaryTitle = routeSummaryTitle
    const distanceMetres = route.summary.totalDistMeters
    const duration = {
      journey: getTotalRouteDuration(route),
      driving: {
        totalSeconds: route.summary.totalDriveDurationSeconds,
        hours: Math.floor(route.summary.totalDriveDurationSeconds / 3600),
        minutes: Math.floor((route.summary.totalDriveDurationSeconds % 3600) / 60),
      },
      charge: {
        totalSeconds: route.summary.totalChargeDurationSeconds,
        hours: Math.floor(route.summary.totalChargeDurationSeconds / 3600),
        minutes: Math.floor((route.summary.totalChargeDurationSeconds % 3600) / 60),
      },
    }

    GTM.openRouteDetails({ summaryTitle, distanceMetres, duration })
  }

  return (
    <div className={styles.root}>
      <button
        className={tw({
          [styles.toggle.button.root]: true,
          [styles.toggle.button.expanded]: expanded,
          [styles.toggle.button.minimised]: !expanded,
        })}
        onClick={onExpand}
      >
        {!expanded && <ChevronDownIcon className={styles.toggle.icon} />}

        {routeSummaryTitle && (
          <Typography
            variant="h3"
            className={tw({
              [styles.toggle.title.root]: true,
              [styles.toggle.title.minimised]: !expanded,
            })}
          >
            <>
              {mapRouteSummaryTitleToIcon(routeSummaryTitle)}
              {t(routeSummaryTitle)}
            </>
          </Typography>
        )}
        <Typography variant="h4" className={styles.toggle.button.text}>
          {`${journeyDuration?.hours ?? ''} ${journeyDuration?.minutes ?? ''}`}
        </Typography>
      </button>
      {expanded && (
        <div className={styles.details.root}>
          <Typography variant="small" className={styles.details.item}>
            <RoutePlannerIcon className={styles.details.icon} />
            {localisedTotalDistanceTraveled}
          </Typography>
          <Typography variant="small" className={styles.details.item}>
            <MapPinIcon className={styles.details.icon} />
            {route.summary?.stopCount}{' '}
            <span className="ml-1">{t('map.route_planner.details.stop_count')}</span>
          </Typography>

          {chargeDuration.hours || chargeDuration.minutes ? (
            <Typography variant="small" className={styles.details.item}>
              <BoltIcon className={styles.details.icon} />
              {chargeDuration?.hours} {chargeDuration?.minutes}{' '}
              {t('map.route_planner.details.charge_time')}
            </Typography>
          ) : null}

          <Button
            className={styles.details.button}
            size="xs"
            onClick={() => {
              onSelect()
              sendRouteDetailsAnalytics()
            }}
          >
            {t('map.route_planner.button.details')}
          </Button>
        </div>
      )}
    </div>
  )
}
