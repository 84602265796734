type Percent = number

interface GetRouteColorFromPercentArgs {
  percent: Percent
}

interface InterpolateRgbValueArgs {
  percent: Percent
  start: number
  end: number
}

type RgbColor = [number, number, number]

export const LOW_CHARGE_RED: RgbColor = [225, 36, 36]
export const MIDDLE_CHARGE_ORANGE: RgbColor = [255, 137, 29]
export const FULL_CHARGE_GREEN: RgbColor = [50, 205, 50]

const COLOR_STOP_PERCENTAGE = 30
const ALWAYS_GREEN_THRESHOLD = 40

function sanitizePercentageValue(percent: Percent): Percent {
  let sanitizedPercent = percent
  if (percent > 100) sanitizedPercent = 100
  if (percent < 0) sanitizedPercent = 0
  return sanitizedPercent
}

function interpolateRgbValue({ percent, start, end }: InterpolateRgbValueArgs): number {
  /**
   * We need to modify the initial percentage so it can be used to calculate the % between color stops.
   * */
  const modifiedPercentage =
    percent < 30
      ? (percent * 3.3333) / 100
      : (percent - COLOR_STOP_PERCENTAGE) / (ALWAYS_GREEN_THRESHOLD - COLOR_STOP_PERCENTAGE)

  const diff = (start - end) * modifiedPercentage

  return Math.round(start - diff)
}
function componentToHex(c) {
  const hex = c.toString(16)
  return hex.length === 1 ? `0${hex}` : hex
}

function rgbToHex(r, g, b) {
  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`
}

export default function getRouteColorFromBatteryPercent({
  percent,
}: GetRouteColorFromPercentArgs): { rgb: RgbColor; hex: string } {
  const sanitizedPercent = sanitizePercentageValue(percent)
  let startColor = LOW_CHARGE_RED
  let endColor = MIDDLE_CHARGE_ORANGE

  if (sanitizedPercent > COLOR_STOP_PERCENTAGE) {
    startColor = MIDDLE_CHARGE_ORANGE
    endColor = FULL_CHARGE_GREEN
  }

  let rgb: RgbColor = [
    interpolateRgbValue({ percent: sanitizedPercent, start: startColor[0], end: endColor[0] }),
    interpolateRgbValue({ percent: sanitizedPercent, start: startColor[1], end: endColor[1] }),
    interpolateRgbValue({ percent: sanitizedPercent, start: startColor[2], end: endColor[2] }),
  ]
  let hex = rgbToHex(...rgb)

  if (percent >= ALWAYS_GREEN_THRESHOLD) {
    rgb = FULL_CHARGE_GREEN
    hex = rgbToHex(...FULL_CHARGE_GREEN)
  }

  return { rgb, hex }
}
