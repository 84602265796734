import { HTMLAttributes } from 'react'
import { useMapConfig, useRoute } from '@electro/consumersite/src/components/Map/hooks'
import { tw } from '@electro/shared/utils/tailwind-merge'

const styles = {
  root: tw(
    'absolute lg:left-4 lg:top-4',
    'h-full w-full lg:w-[calc(100%-2rem)]',
    'pointer-events-none [&>*]:pointer-events-auto',
  ),
  withPadding: 'top-16 lg:top-20',
}

export const MapControlsV2 = ({ children }: HTMLAttributes<HTMLDivElement>) => {
  const [{ routes }] = useRoute()
  const [{ showNavbar }] = useMapConfig()

  return !routes ? (
    <div
      data-testid="map-controls-v2"
      className={tw({
        [styles.root]: true,
        [styles.withPadding]: showNavbar,
      })}
    >
      {children}
    </div>
  ) : null
}
