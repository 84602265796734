
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { useEffect } from 'react';
import { ToastNotifications, ErrorBoundary } from '@electro/shared-ui-components';
import { ApolloProvider } from '@apollo/client';
import TagManager from 'react-gtm-module';
import type { AppProps } from 'next/app';
import '../styles/globals.css';
import { client } from '@electro/consumersite/src/client/client';
import { EjnAuthProvider, SourceAttributionProvider } from '@electro/consumersite/src/hooks';
import { DebtStatusModal, OnboardingModal, GoogleSignIn, } from '@electro/consumersite/src/components';
import { useAsPathInitializer } from '@electro/consumersite/src/hooks/stores';
import { getScrollbarVisible } from '@electro/consumersite/src/utils/getScrollbarVisible';
import { useSearchHistoryInitialiser } from '@electro/consumersite/src/components/Map/hooks';
import { useRouter } from 'next/router';
import { featureFlagShowGoogleSignIn } from '@electro/consumersite/src/utils/envFeatureFlags';
const tagManagerArgs = {
    gtmId: 'GTM-N3P3H7S',
};
function MyApp({ Component, pageProps }: AppProps) {
    const { asPath } = useRouter();
    useEffect(() => {
        if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' && !asPath.startsWith('/map/embed')) {
            TagManager.initialize(tagManagerArgs);
            // Prevent cookie banner text from overwriting screen readers
            const cookieElements = [
                ...document.getElementsByClassName('cky-consent-container'),
                ...document.getElementsByClassName('cky-modal'),
            ];
            cookieElements.forEach((element) => {
                // eslint-disable-next-line no-param-reassign
                element.ariaHidden = 'true';
            });
        }
        else
            window.dataLayer = [];
    }, [asPath]);
    useAsPathInitializer();
    useSearchHistoryInitialiser();
    useEffect(() => {
        // Adds scrollbar styling to webkit browsers if scrollbar is visible
        if (getScrollbarVisible()) {
            document.documentElement.className = 'webkit-mouse-only-scrollbar';
            // Adds light mode scrollbar styling to community pages
            if (asPath.startsWith('/community') &&
                !document.documentElement.className.includes('override-dark-scrollbar')) {
                document.documentElement.className += ' override-dark-scrollbar';
            }
        }
    }, [asPath]);
    return (<ApolloProvider client={client}>
      <EjnAuthProvider>
        <SourceAttributionProvider>
          <ErrorBoundary>
            <ToastNotifications>
              <GoogleSignIn>
                {featureFlagShowGoogleSignIn ? <GoogleSignIn.Prompt /> : null}
                <Component {...pageProps}/>
                <OnboardingModal />
                <DebtStatusModal />
              </GoogleSignIn>
            </ToastNotifications>
          </ErrorBoundary>
        </SourceAttributionProvider>
      </EjnAuthProvider>
    </ApolloProvider>);
}
const __Next_Translate__Page__1932aa9d543__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__1932aa9d543__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  