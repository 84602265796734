import {
  RouteDetailsPanelSummary,
  RouteDetailsPanelSteps,
  RouteDetailsPanelActions,
} from '@electro/consumersite/src/components/Map/components/RoutePlanner/components'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { useState } from 'react'

const styles = {
  panel: {
    root: tw(
      ' shadow-md px-0 pt-4 fixed bottom-0 transition-all w-full h-full overflow-hidden',
      'sm:rounded-3xl sm:px-5 sm:max-w-sm sm:left-2 sm:right-2 sm:top-20 sm:h-min sm:max-h-[calc(100vh-theme(spacing.24))] sm:overflow-auto sm:pr-2 sm:-mr-2 sm:rounded-t-3xl',
      'bg-base/80 filter backdrop-blur',
    ),
    stepsWrapper: {
      root: tw(
        'max-h-[calc(100vh-theme(spacing.52))] overflow-auto',
        'sm:max-h-fit sm:after:hidden',
        "after:content-[''] after:w-full after:bg-gradient-to-t after:to-transparent after:h-12 after:absolute after:bottom-0 after:pointer-events-none", // bottom gradient hint
        'after:from-base',
      ),
      closed: 'hidden sm:block',
      open: 'block overflow-hidden',
    },
    maximised: 'translate-y-0 sm:translate-y-0 h-screen rounded-none z-40 top-14',
    minimised: 'translate-y-0 sm:translate-y-0 h-auto rounded-t-3xl z-20',
  },
}

/**
 * We need to use forwardRef in order to make this component compatible with  '@headlessui/react/transition'
 */
export const ActiveRouteDetails = () => {
  const [showSteps, setShowSteps] = useState(false)
  function onShowSteps() {
    setShowSteps(!showSteps)
  }
  return (
    <div
      data-testid="route-details-panel"
      className={tw({
        [styles.panel.root]: true,
        [styles.panel.maximised]: showSteps,
        [styles.panel.minimised]: !showSteps,
      })}
    >
      <RouteDetailsPanelSummary onShowSteps={onShowSteps} showSteps={showSteps} />
      <hr className="border-secondary" />
      <div
        className={tw({
          [styles.panel.stepsWrapper.open]: showSteps,
          [styles.panel.stepsWrapper.closed]: !showSteps,
        })}
      >
        <RouteDetailsPanelActions onShowSteps={onShowSteps} />
        <div data-testid="route-steps-layout-wrapper" className={styles.panel.stepsWrapper.root}>
          <RouteDetailsPanelSteps onShowSteps={onShowSteps} />
        </div>
      </div>
    </div>
  )
}
