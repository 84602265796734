import {
  useState,
  useCallback,
  useMemo,
  createContext,
  useContext,
  ReactNode,
  ChangeEvent,
  KeyboardEvent,
  Dispatch,
  SetStateAction,
} from 'react'
import { useMap } from 'react-map-gl'
import { useDebounce } from 'react-use'
import { useRouter } from 'next/router'
import { useAuth } from '@electro/consumersite/src/hooks'
import { MAP_SEARCH_HISTORY } from '@electro/shared/constants'
import { fetchPlaceSuggestions } from '@electro/shared/services/mapbox'
import { retrievePlaceDetailsPosition } from '@electro/consumersite/src/components/Map/utils'
import { SidebarPanels } from '@electro/consumersite/src/components/Map/types'
import {
  PlaceDetails,
  formatMapboxSearchToPlaceDetails,
  getCameraAnimationOptions,
} from '@electro/consumersite/src/components/Map/helpers'
import {
  useCurrentLocation,
  useMapSidebar,
  useMarkers,
  useRoutePlannerForm,
  useSearchHistoryStore,
} from '@electro/consumersite/src/components/Map/hooks'
import { GTM } from '@electro/consumersite/src/utils/event-triggers'

export interface UseMapSearchProps {
  children: ReactNode | ReactNode[]
  target: 'map' | 'routePlanner'
  initialValue?: PlaceDetails
  placeholder: string
  id?: string
}

export type MapSearchResultTypes = 'mapboxAPI' | 'history' | 'suggestion' | 'currentLocation'

interface UseMapSearchArgs extends Omit<UseMapSearchProps, 'children'> {}

interface State {
  target: UseMapSearchProps['target']
  placeholder: UseMapSearchProps['placeholder']
  id: UseMapSearchProps['id']
  showSearchHistory: boolean
  showSearchResults: boolean
  showSearchNotFound: boolean
  search: string
  searchHistory: PlaceDetails[]
  placeSuggestions: PlaceDetails[]
  selectedPlace: PlaceDetails
}

// prettier-ignore
interface Handlers {
  setShowSearchHistory: Dispatch<SetStateAction<boolean>>
  setShowSearchResults: Dispatch<SetStateAction<boolean>>
  setShowSearchNotFound: Dispatch<SetStateAction<boolean>>
  handleLocationSearchPlaceSelection: ({ place, searchType }: { place: PlaceDetails; searchType?: MapSearchResultTypes }) => void
  handleLocationSearchInputChange: (event: ChangeEvent<HTMLInputElement> & KeyboardEvent<HTMLInputElement>) => void
  handleLocationSearchInputClick: () => void
  handleCloseSearch: () => void
  handleClearSearch: () => void
  setSearch: Dispatch<SetStateAction<string>>
}

type UseMapSearch = [state: State, handlers: Handlers]

const UseMapSearchContext = createContext<UseMapSearch>(null)

function useMapSearchProvider({
  target,
  initialValue,
  placeholder,
  id,
}: UseMapSearchArgs): UseMapSearch {
  const { baseMap } = useMap()
  const { locale } = useRouter()
  const [{ sessionToken }] = useAuth()
  const [{ visiblePanel }] = useMapSidebar()
  const [{ currentLocationDetails }] = useCurrentLocation()
  const [{ destinations }, { updateDestinations }] = useRoutePlannerForm({})
  const [, { clearActiveMarker, clearSearchMarker, toggleLocationDetailsPanel, setSearchMarker }] =
    useMarkers()

  const [showSearchHistory, setShowSearchHistory] = useState<boolean>(false)
  const [showSearchResults, setShowSearchResults] = useState<boolean>(false)
  const [showSearchNotFound, setShowSearchNotFound] = useState<boolean>(false)

  const [search, setSearch] = useState<string>(initialValue?.name || '')
  const [selectedPlace, setSelectedPlace] = useState<PlaceDetails>(initialValue)
  const [placeSuggestions, setPlaceSuggestions] = useState<PlaceDetails[]>([])

  const searchHistory = useSearchHistoryStore((state) => state.searchHistory)
  const setSearchHistory = useSearchHistoryStore((state) => state.setSearchHistory)

  /** Removes duplicates and custom marker locations from history before updating both state and local storage */
  const updateSearchHistoryItems = useCallback(
    (selection: PlaceDetails) => {
      if (selection.hasCustomMarker) return

      const updatedHistoryItems = [selection, ...searchHistory].reduce(
        (total: PlaceDetails[], current) => {
          if (!total.find((item) => item.id === current.id)) total.push(current)
          return total
        },
        [],
      )

      while (updatedHistoryItems.length > 4) updatedHistoryItems.pop()

      setSearchHistory(updatedHistoryItems)
      localStorage.setItem(MAP_SEARCH_HISTORY, JSON.stringify(updatedHistoryItems))
    },
    [searchHistory, setSearchHistory],
  )

  /** Move to location and add a marker. Only applies when using the map searchbar */
  const showSearchLocationOnMap = useCallback(
    (selection: PlaceDetails) => {
      const { lng, lat } = selection.coordinates
      const isSidebarOpen = visiblePanel !== SidebarPanels.MAP

      // When a bounding box isn't provided by the MapBox API, we centre on the location with an arbitrary zoom level
      if (selection?.bbox?.length > 0) {
        baseMap?.fitBounds(selection.bbox, {
          ...getCameraAnimationOptions({ type: 'fitBounds', isSidebarOpen }),
          ...(selection.maxZoom ? { maxZoom: selection.maxZoom } : {}),
        })
      } else
        baseMap?.flyTo({
          ...getCameraAnimationOptions({ isSidebarOpen }),
          ...(selection.maxZoom ? { zoom: selection.maxZoom } : {}),
          center: [lng, lat],
        })

      // Show the search marker only if we don't have a pre-existing marker for the location
      if (!selection.hasCustomMarker) setSearchMarker({ lat, lng })

      toggleLocationDetailsPanel({ open: false })
      clearActiveMarker()
    },
    [visiblePanel, baseMap, setSearchMarker, toggleLocationDetailsPanel, clearActiveMarker],
  )

  /** Sends all locations to the route planner with an updated PlaceDetails for this ID */
  const updateRoutePlannerForm = useCallback(
    (selection?: PlaceDetails) => {
      const updatedDestinations = { ...destinations, [id]: selection }
      if (!selection) delete updatedDestinations[id]
      updateDestinations(updatedDestinations)
    },
    [updateDestinations, destinations, id],
  )

  /** Show initial menu view (current location, history, etc.) instead of search results */
  const resetPlaceSuggestions = useCallback(() => setPlaceSuggestions([]), [])

  /** Quickly close all searchbar menus */
  const handleCloseSearch = useCallback(() => {
    setShowSearchHistory(false)
    setShowSearchResults(false)
    setShowSearchNotFound(false)
  }, [])

  /** Reset the searchbar to the initial empty state */
  const handleClearSearch = useCallback(() => {
    setSelectedPlace(null)
    resetPlaceSuggestions()
    setShowSearchNotFound(false)
    if (target === 'map') clearSearchMarker()

    if (search.length > 0) {
      setSearch('')
      if (baseMap && target === 'map') {
        baseMap.setZoom(baseMap.getZoom() + 0.000001) // Stop camera animation
      } else if (target === 'routePlanner') updateRoutePlannerForm()
    }
  }, [resetPlaceSuggestions, target, clearSearchMarker, search, baseMap, updateRoutePlannerForm])

  /** Activates that location on the map, stores the selection and resets for next search */
  const handleLocationSearchPlaceSelection = useCallback(
    async ({ place, searchType }: { place: PlaceDetails; searchType?: MapSearchResultTypes }) => {
      setShowSearchHistory(false)
      const selection = place.coordinates
        ? place
        : await retrievePlaceDetailsPosition({ place, sessionToken, locale })

      handleCloseSearch()
      resetPlaceSuggestions()
      setSelectedPlace(selection)
      setSearch(selection?.name)
      updateSearchHistoryItems(selection)
      if (target === 'map') showSearchLocationOnMap(selection)
      else if (target === 'routePlanner') updateRoutePlannerForm(selection)
      GTM.submitMapSearch({ target, searchType: searchType || 'mapboxAPI' })
    },
    [
      sessionToken,
      locale,
      target,
      resetPlaceSuggestions,
      updateSearchHistoryItems,
      handleCloseSearch,
      showSearchLocationOnMap,
      updateRoutePlannerForm,
    ],
  )

  /** Updates the search query in state */
  const handleLocationSearchInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement> & KeyboardEvent<HTMLInputElement>) =>
      setSearch(event.currentTarget.value),
    [],
  )

  /** Determines which menu items to show based on the search text */
  const handleLocationSearchInputClick = useCallback(() => {
    const noMatchInHistory = !searchHistory.some((item) => item.name === search)
    const notCustomLocation = placeSuggestions.length > 0 && !placeSuggestions[0].hasCustomMarker

    if (search.length > 0 && noMatchInHistory && notCustomLocation) {
      if (placeSuggestions.length > 0) setShowSearchResults(true)
      else setShowSearchNotFound(true)
    } else setShowSearchHistory(true)
  }, [search, placeSuggestions, searchHistory, setShowSearchHistory])

  /** Retrieve MapBox suggestions, format them into PlaceDetails and toggle the searchbar menus.
   *  We wait for the debounced search text value to prevent bombarding the API with requests. */
  const getPlaceSuggestions = async (searchQuery: string) => {
    const { lng, lat } = currentLocationDetails?.coordinates || {}
    const origin = target === 'routePlanner' ? destinations?.start?.coordinates : undefined

    const { suggestions } = await fetchPlaceSuggestions({
      searchQuery,
      sessionToken,
      locale,
      ...(lng && lat ? { proximity: `${lng},${lat}` } : {}),
      ...(origin ? { origin: `${origin.lng},${origin.lat}` } : {}),
    })

    setShowSearchHistory(false)

    if (suggestions?.length > 0) {
      setShowSearchResults(true)
      setShowSearchNotFound(false)
      setPlaceSuggestions(formatMapboxSearchToPlaceDetails(suggestions))
    } else {
      resetPlaceSuggestions()
      setShowSearchNotFound(true)
    }
  }

  // prettier-ignore
  useDebounce(() => {
    // Adds a 250ms delay before sending the search query to the MapBox API
    // This is to prevent the API from being bombarded with requests if the user types quickly
    if (search.length > 0 && search !== selectedPlace?.name) getPlaceSuggestions(search)
  }, 250, [search])

  const state = useMemo(
    () => ({
      target,
      placeholder,
      id,
      showSearchHistory,
      showSearchResults,
      showSearchNotFound,
      search,
      searchHistory,
      placeSuggestions,
      selectedPlace,
    }),
    [
      target,
      placeholder,
      id,
      showSearchHistory,
      showSearchResults,
      showSearchNotFound,
      search,
      searchHistory,
      placeSuggestions,
      selectedPlace,
    ],
  )

  const handlers = useMemo(
    () => ({
      setShowSearchHistory,
      setShowSearchResults,
      setShowSearchNotFound,
      handleLocationSearchPlaceSelection,
      handleLocationSearchInputChange,
      handleLocationSearchInputClick,
      handleCloseSearch,
      handleClearSearch,
      setSearch,
    }),
    [
      setShowSearchHistory,
      setShowSearchResults,
      setShowSearchNotFound,
      handleLocationSearchPlaceSelection,
      handleLocationSearchInputChange,
      handleLocationSearchInputClick,
      handleCloseSearch,
      handleClearSearch,
      setSearch,
    ],
  )

  return [state, handlers]
}

export const UseMapSearchProvider = ({ children, ...args }: UseMapSearchProps) => {
  const ctx = useMapSearchProvider(args)
  return <UseMapSearchContext.Provider value={ctx}>{children}</UseMapSearchContext.Provider>
}

export const useMapSearch = (): UseMapSearch => {
  const context = useContext(UseMapSearchContext)
  if (!context) throw new Error('useMapSearch() cannot be used outside of <UseMapSearchProvider/>')
  return context
}
