import { BoltIcon } from '@heroicons/react/24/solid'
import { useEffect, useRef, useState, useMemo } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'

interface BatteryChargeProps {
  percent: number
}

const styles = {
  level: {
    root: 'absolute w-full h-3 z-0 rounded-full left-0 transition-colors',
    high: 'bg-action-success',
    mid: 'bg-action-warning',
    low: 'bg-action-danger',
  },
}

export const BatteryCharge = ({ percent }: BatteryChargeProps) => {
  const [width, setWidth] = useState(0)

  const SpanRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    const p = percent / 100

    setWidth(SpanRef.current.offsetWidth * p)
  }, [percent])

  const highCharge = useMemo(() => percent > 40, [percent])
  const medCharge = useMemo(() => percent <= 40 && percent > 14, [percent])
  const lowCharge = useMemo(() => percent <= 14, [percent])

  return (
    <span
      ref={SpanRef}
      className="inline-flex h-3 bg-tertiary-shade overflow-hidden rounded-full w-8 items-center justify-center relative"
    >
      <BoltIcon className="w-2 h-2 text-white relative z-10" />
      <span
        data-testid="battery-charge-level"
        className={tw({
          [styles.level.root]: true,
          [styles.level.high]: highCharge,
          [styles.level.mid]: medCharge,
          [styles.level.low]: lowCharge,
        })}
        style={{ width }}
      />
    </span>
  )
}
