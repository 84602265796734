import { RouteFormFieldsType } from '@electro/consumersite/src/components/Map/constants'
import { RouteFormFields } from '@electro/consumersite/src/components/Map/types'

export const convertRoutePlannerFormSubmitToExistingFormat = (
  formFields: RouteFormFieldsType,
): RouteFormFields => ({
  selectedVehicle: formFields.activeVehicle,
  startingLocation: formFields.startingLocation,
  waypoints: formFields.waypoints,
  destination: formFields.destination,
  startingBatteryPerc: formFields.startCharge,
  destinationBatteryPerc: formFields.endCharge,
  ejLocationsOnly: formFields.electroverseOnly,
  avoidTolls: formFields.avoidTolls,
})
