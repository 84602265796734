import { RoutePathsData, Route } from '@electro/consumersite/src/components/Map/types'
import getFastestRouteId from './getFastestRouteId'

interface GetRouteSummaryTitlesArgs {
  routes: RoutePathsData
  route: Route
}

export enum RouteTitleEnums {
  FASTEST_ROUTE = 'map.route_planner.details.fastest_route',
  SHORTEST_CHARGE = 'map.route_planner.details.shortest_charge',
  SHORTEST_ROUTE = 'map.route_planner.details.shortest_route',
}

export type RouteTitles = `${RouteTitleEnums}`

/**
 * getRouteSummaryTitles() compares dictionary object of routes to a given route to
 * classify it and return a string to the user.
 * @param route Individual route date transformed from the API request.
 * @param routes Routes summary object containing a Dictionary of routes with the route Id as key.
 * @returns a string defining the classification of a given route plan compared to the other routes
 */

export default function getRouteSummaryTitles({
  route,
  routes,
}: GetRouteSummaryTitlesArgs): RouteTitles {
  if (routes?.routeCount <= 1) return null

  const fastestRouteId = getFastestRouteId(routes)

  // We don't want to return a shortest charge ID if the route does not involve any charge stops!
  // we are assuming that if the charge time is 0 then no charge stops have occurred.
  const shortestChargeId =
    route.summary.totalChargeDurationSeconds > 0
      ? Object.values(routes.data).reduce((prev, curr) =>
          prev.summary.totalChargeDurationSeconds < curr.summary.totalChargeDurationSeconds
            ? prev
            : curr,
        ).id
      : null

  const shortestDistanceId = Object.values(routes.data).reduce((prev, curr) =>
    prev.summary.totalDistMeters < curr.summary.totalDistMeters ? prev : curr,
  ).id

  switch (route?.id) {
    case fastestRouteId:
      return RouteTitleEnums.FASTEST_ROUTE

    case shortestChargeId:
      return RouteTitleEnums.SHORTEST_CHARGE

    case shortestDistanceId:
      return RouteTitleEnums.SHORTEST_ROUTE

    default:
      return null
  }
}
