export const RoutePlannerIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.48 20.5723C18.48 20.5723 23.9253 16.6121 23.9253 11.9918C23.9253 10.4164 23.3516 8.90542 22.3304 7.79139C21.3092 6.67737 19.9241 6.05151 18.48 6.05151C17.0358 6.05151 15.6507 6.67737 14.6296 7.79139C13.6084 8.90542 13.0347 10.4164 13.0347 11.9918C13.0347 16.6121 18.48 20.5723 18.48 20.5723ZM20.2951 11.4968C20.2951 12.4993 19.4825 13.3119 18.48 13.3119C17.4776 13.3119 16.6649 12.4993 16.6649 11.4968C16.6649 10.4944 17.4776 9.68172 18.48 9.68172C19.4825 9.68172 20.2951 10.4944 20.2951 11.4968Z"
      fill="currentColor"
    />
    <path
      d="M4.93164 11.3186C7.36258 10.5082 12.2244 11.7238 8.17289 14.965C2.288 19.6729 8.1729 23.4733 18.7069 19.0165"
      stroke="currentColor"
      strokeWidth="1.62062"
    />
    <circle cx="4.52629" cy="5.6464" r="3.6464" stroke="currentColor" strokeWidth="1.62062" />
    <line
      x1="4.5265"
      y1="9.69789"
      x2="4.5265"
      y2="11.3185"
      stroke="currentColor"
      strokeWidth="2.43094"
      strokeLinecap="round"
    />
  </svg>
)
