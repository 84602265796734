import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import { useRoute } from '@electro/consumersite/src/components/Map/hooks'
import { RouteDetailStagesEnum } from '@electro/consumersite/src/components/Map/types'
import {
  SelectRoute,
  ActiveRouteDetails,
} from '@electro/consumersite/src/components/Map/components/RoutePlanner/components'

export const RouteDetailsPanel = () => {
  const [{ routeDetailsPanelOpen, routeDetailsPanelScreen }] = useRoute()

  return routeDetailsPanelOpen ? (
    <Transition
      as={Fragment}
      show={routeDetailsPanelOpen}
      enter="transition ease-in-out sm:duration-300"
      enterFrom="sm:-translate-x-full"
      enterTo="sm:translate-x-0"
      leave="transition ease-in-out sm:duration-300"
      leaveFrom="sm:translate-x-0"
      leaveTo="sm:-translate-x-full"
    >
      <div data-testid="panel" className="fixed left-0 sm:max-w-sm w-full z-40">
        {routeDetailsPanelScreen === RouteDetailStagesEnum.SELECTING_ROUTE && <SelectRoute />}
        {routeDetailsPanelScreen === RouteDetailStagesEnum.VIEWING_ROUTE_STEPS && (
          <ActiveRouteDetails />
        )}
      </div>
    </Transition>
  ) : null
}
