import {
  RouteFormLayer,
  RouteGhostLayer,
  RouteLayer,
} from '@electro/consumersite/src/components/Map/components/MapLayers/RoutePlanLayer/components'

export const RoutePlanLayer = () => (
  <>
    <RouteFormLayer />
    <RouteGhostLayer />
    <RouteLayer />
  </>
)
