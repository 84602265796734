import { TableCell } from '@electro/shared-ui-components'
import { DataGridColumnDef, DataGridRowDef } from '../..'

interface DataGridRowProps {
  row: DataGridRowDef
  column: DataGridColumnDef
}

export const DataGridCell = ({ row, column }: DataGridRowProps) => {
  const value = row[column.field]

  if (column.valueGetter) {
    return <TableCell>{column.valueGetter(value, row)}</TableCell>
  }

  if (column.renderCell) {
    return <TableCell>{column.renderCell(value, row)}</TableCell>
  }

  return <TableCell>{value}</TableCell>
}
