import { Marker } from 'react-map-gl'
import { useMarkers } from '@electro/consumersite/src/components/Map/hooks'

export const SearchMarkerLayer = () => {
  const [{ searchMarker }] = useMarkers()

  return searchMarker ? (
    <Marker longitude={searchMarker.lng} latitude={searchMarker.lat} anchor="bottom">
      <img src="/images/markers/center.png" width={30} alt="Center marker" />
    </Marker>
  ) : null
}
