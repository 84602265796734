import { Step } from '@electro/consumersite/generated/graphql'
import { MarkerNames, MarkerNamesEnum } from './getMarkerIcon'

interface GetIconNameFromStepArgs {
  step: Step
  steps: Step[]
  index: number
}

// For details on what these fields mean see Internios APi docs:
// https://documenter.getpostman.com/view/7396339/SWTK3YsN#0796780b-0c95-46db-b003-a6387ca0ca6f
export function getIconNameFromStep({ step, steps, index }: GetIconNameFromStepArgs): MarkerNames {
  if (step.isDestcharger && index !== 0 && index !== steps.length - 1)
    return MarkerNamesEnum.ROUTE_WAYPOINT
  if (step.isWaypoint) return MarkerNamesEnum.ROUTE_DESTINATION
  if (step.isStation || step.isEndStation) return MarkerNamesEnum.ROUTE_WAYPOINT
  return MarkerNamesEnum.ROUTE_CHARGE_STOP
}
