import { tw } from '@electro/shared/utils/tailwind-merge'
import { ReactNode } from 'react'
import { useMapConfig } from '@electro/consumersite/src/components/Map/hooks'

const styles = {
  root: 'absolute w-full',
  noPadding: 'pt-2',
  withPadding: 'lg:pt-18 pt-16 ',
  topControls: tw(
    'px-0 lg:bg-white max-w-6xl m-auto z-10',
    'flex flex-col justify-evenly items-start gap-4',
    'fixed bottom-10 left-2',
    'lg:bg-transparent lg:px-2 lg:static',
    'lg:flex-row',
  ),
}

interface MapControlsProps {
  children: ReactNode | ReactNode[]
}

export const MapControls = ({ children }: MapControlsProps) => {
  const [{ showNavbar }] = useMapConfig()
  return (
    <div
      data-testid="map-controls"
      className={tw({
        [styles.root]: true,
        [styles.noPadding]: !showNavbar,
        [styles.withPadding]: showNavbar,
      })}
    >
      <div className={styles.topControls}>{children}</div>
    </div>
  )
}
