import useTranslation from 'next-translate/useTranslation'
import { loadStripe, Appearance, StripeElementLocale } from '@stripe/stripe-js'
import { useMemo, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import * as Sentry from '@sentry/nextjs'
import { useRouter } from 'next/router'

import { extend as theme } from '@electro/shared/theme/electro'

import { Alert } from '@electro/shared-ui-components'

import {
  useGetStripePublicKeyMutation,
  useStoreStripePaymentMethodFromSetupIntentMutation,
  useGetEmbeddedSecretForNewPaymentInstructionMutation,
  StoreStripePaymentMethodFromSetupIntentMutationVariables,
  StoreStripePaymentMethodFromSetupIntent,
} from '@electro/consumersite/generated/graphql'
import { Locale } from '@electro/shared/types/locales'
import { useMount } from 'react-use'

interface UseStripePaymentSetup {
  getStripeSetupIntentSecretKey: () => Promise<string>
  confirmStripePaymentMethod: (
    setupIntentId: string,
  ) => Promise<StoreStripePaymentMethodFromSetupIntent>
}

// https://docs.stripe.com/elements/appearance-api
const appearance: Appearance = {
  theme: 'night',
  variables: {
    fontFamily: 'Rubik',
    fontWeightNormal: '500',
    borderRadius: '8px',
    colorBackground: theme.colors.base.DEFAULT,
    colorPrimary: theme.colors.secondary.DEFAULT,
    accessibleColorOnColorPrimary: '#fff',
    colorText: 'white',
    colorTextSecondary: 'white',
    colorTextPlaceholder: '#727F96',
    tabIconColor: 'white',
    logoColor: 'dark',
  },
  rules: {
    '.Input, .Block': {
      backgroundColor: 'transparent',
      border: '2px solid var(--colorPrimary)',
    },
    '.Input--focus, .Block--focus': {
      borderColor: 'var(--colorPrimary)',
    },
  },
}

const generateStripePromise = ({ locale, key }: { locale: Locale; key: string }) =>
  loadStripe(key, { locale: locale as StripeElementLocale })

export function useStripePaymentSetup(): UseStripePaymentSetup {
  const [getStripeSetupIntentId] = useGetEmbeddedSecretForNewPaymentInstructionMutation()
  const [storePaymentMethod] = useStoreStripePaymentMethodFromSetupIntentMutation()

  async function confirmStripePaymentMethod(setupIntentId: string) {
    // Stripe requires a time and date to setup a payment.
    // To simplify testing we are setting this to
    // the last midnight.
    const validFromDate = new Date()
    validFromDate.setHours(0, 0, 0, 0)
    const validFrom = validFromDate.toISOString()

    const storePaymentVariables: StoreStripePaymentMethodFromSetupIntentMutationVariables = {
      setupIntentId,
      validFrom,
    }

    const { data } = await storePaymentMethod({
      variables: storePaymentVariables,
    })

    return data.storeStripePaymentMethodFromSetupIntent
  }

  async function getStripeSetupIntentSecretKey(): Promise<string> {
    const { data } = await getStripeSetupIntentId()
    return data?.getEmbeddedSecretForNewPaymentInstruction?.secretKey
  }

  return {
    getStripeSetupIntentSecretKey,
    confirmStripePaymentMethod,
  }
}

export const StripeProvider = ({ children }) => {
  const router = useRouter()
  const { t } = useTranslation('common')
  const [getStripeKey] = useGetStripePublicKeyMutation()
  const { getStripeSetupIntentSecretKey } = useStripePaymentSetup()
  const [errorMessage, setErrorMessage] = useState<string>(null)
  const [stripeSecret, setStripeSecret] = useState<string>(null)

  const stripe = useMemo(async () => {
    /**
     * Stripe keys are stored in the back end.
     * In order to facilitate a migration to a new
     * stripe account older users (pre summer 2023) will require
     * a different key to newer users. On the client side we currently
     * have no way of knowing if a user is old or new so we have to
     * fetch the key from the back end.
     */
    try {
      const { data } = await getStripeKey()
      if (data) {
        return generateStripePromise({
          locale: router.locale as Locale,
          key: data.getStripePublicKey.key,
        })
      }
      return null
    } catch (err) {
      setErrorMessage('errors.could_not_load_stripe')
      Sentry.captureException(err)
      return null
    }
  }, [getStripeKey, router.locale])

  useMount(() => {
    async function setStripeSetupIntentId() {
      const secret = await getStripeSetupIntentSecretKey()
      setStripeSecret(secret)
    }
    setStripeSetupIntentId()
    return () => {
      setStripeSecret(null)
    }
  })

  if (errorMessage) {
    return (
      <Alert variant="error" className="my-6">
        {t('errors.could_not_load_stripe')}
      </Alert>
    )
  }
  return stripe && stripeSecret ? (
    <Elements
      options={{
        clientSecret: stripeSecret,
        appearance,
        fonts: [
          {
            cssSrc:
              'https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;600;800&display=swap',
          },
        ],
      }}
      stripe={stripe}
    >
      {children}
    </Elements>
  ) : null
}
