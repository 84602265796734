import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useMap } from 'react-map-gl'
import { v1 as uuid } from 'uuid'
import * as Sentry from '@sentry/nextjs'

import { useMapModal, useMarkers } from '@electro/consumersite/src/components/Map/hooks'
import { getIconNameFromStep } from '@electro/consumersite/src/components/Map/helpers/getIconNameFromStep'
import getFastestRouteId from '@electro/consumersite/src/components/Map/helpers/getFastestRouteId'

import { PlanResponse, Step } from '@electro/consumersite/generated/graphql'
import {
  RouteDetailStages,
  RouteDetailStagesEnum,
  RouteLineGeoJson,
  RoutePathsData,
  RoutesDictionary,
  RouteStatusEnum,
  RouteSummary,
  RouteFormFields,
  RouteDestinationAddress,
  WaypointDictionary,
  EjnMarker,
  ModalScreenNames,
  RouteStatus,
} from '@electro/consumersite/src/components/Map/types'
import { useCreateRoutePlan } from '@electro/consumersite/src/services'
import { GTM } from '@electro/consumersite/src/utils/event-triggers'

const SM_BREAKPOINT = 640

interface State {
  error: RouteStatus
  loading: boolean
  /** @routes - a list of all routes data */
  routes: RoutePathsData
  activeRouteId: string
  /** @routeFormFields - Form fields for the route planner form */
  routeFormFields: RouteFormFields
  /** @routeDetailsPanelScreen - enum for the active route panel screen */
  routeDetailsPanelScreen: RouteDetailStages
  routeDetailsPanelOpen: boolean
}

interface Handlers {
  handleNewRouteSubmit: (formfields: RouteFormFields) => void
  toggleRouteDetailsPanel: ({ open }?: { open?: boolean }) => void
  clearRoutes: () => void
  selectActiveRoute: (routeId: string) => void
  /** @setRouteDetailsPanelScreen - sets the visible screen for route details panel, does not draw the route to the map */
  setRouteDetailsPanelScreen: (screen: RouteDetailStages) => void
  getRouteMarkers: ({ steps }: { steps: Step[] }) => EjnMarker[]
}

type UseRouteContext = [state: State, handlers: Handlers]

const DEFAULT_FORM_FIELDS = {
  selectedVehicle: null,
  startingLocation: null,
  waypoints: {},
  destination: null,
  startingBatteryPerc: 80,
  destinationBatteryPerc: 15,
  ejLocationsOnly: false,
  avoidTolls: false,
}

export function useRouteProvider(): UseRouteContext {
  const [error, setError] = useState<RouteStatus>(null)
  const [formfields, setFormfields] = useState<RouteFormFields>(DEFAULT_FORM_FIELDS)
  const [routes, setRoutes] = useState<RoutePathsData>(null)
  const [routeDetailsPanelScreen, setRouteDetailsPanelScreen] = useState<RouteDetailStages>(
    RouteDetailStagesEnum.SELECTING_ROUTE,
  )
  const [activeRouteId, setActiveRouteId] = useState<string>(null)
  const [routeDetailsPanelOpen, setrouteDetailsPanelOpen] = useState<boolean>(false)

  const [getRoutePlan, routePlanMutation] = useCreateRoutePlan({
    notifyOnNetworkStatusChange: true,
  })

  const { baseMap } = useMap()

  const [, { closeModal, setActiveModalScreen }] = useMapModal()
  const [, { clearActiveMarker, toggleLocationDetailsPanel, clearSearchMarker }] = useMarkers()

  const toggleRouteDetailsPanel = ({ open }: { open?: boolean } = {}) => {
    setrouteDetailsPanelOpen((oldValue) => {
      if (open === false) return open
      if (open) return open
      return !oldValue
    })
  }

  const getRouteMarkers = useCallback(
    ({ steps }: { steps: Step[] }): EjnMarker[] =>
      steps
        .map((step: Step, index: number) => ({
          coordinates: [step.lon, step.lat],
          id: step?.charger?.ejnChargingLocationId
            ? step?.charger?.ejnChargingLocationId
            : step?.charger?.ecoChargingLocationId,
          isEjnLocation: true,
          icon: getIconNameFromStep({ step, steps, index }),
        }))
        .sort((a, b) => a.coordinates[1] - b.coordinates[1]),

    [],
  )

  const fitMapBoundsToRouteLine = useCallback(
    (routeLineGeoJson: RouteLineGeoJson): void => {
      const allLongitudesAlongRoutePath = routeLineGeoJson
        .map(({ features: [lineData] }) => lineData.geometry.coordinates?.map((path) => [path[0]]))
        .flat(2)

      const allLatitudesAlongRoutePath = routeLineGeoJson
        .map(({ features: [lineData] }) => lineData.geometry.coordinates?.map((path) => [path[1]]))
        .flat(2)

      const maxLng = Math.max(...allLongitudesAlongRoutePath)
      const minLng = Math.min(...allLongitudesAlongRoutePath)
      const maxLat = Math.max(...allLatitudesAlongRoutePath)
      const minLat = Math.min(...allLatitudesAlongRoutePath)

      let padding = { top: 170, left: 480, right: 40, bottom: 120 }
      if (window.innerWidth < SM_BREAKPOINT) {
        padding = { top: 120, left: 40, right: 40, bottom: 200 }
      }
      const swCorner = { lat: minLat, lng: minLng }
      const neCorner = { lat: maxLat, lng: maxLng }

      baseMap?.fitBounds([neCorner, swCorner], { padding })
    },
    [baseMap],
  )

  useEffect(() => {
    if (routes?.data[activeRouteId]) {
      fitMapBoundsToRouteLine(routes.data[activeRouteId].polyLine)
    }
  }, [activeRouteId, routes, fitMapBoundsToRouteLine])

  /**
   * We want to be able to draw a gradient line for each step of the users desired route.
   * In order to do that we need to structure each step of the route as FeatureCollection
   * geojson type. This means we can attach the start/finish battery % as Feature properties.
   *
   * These feature collections are then mapped in the <RouteLayer/> component so we can calculate a
   * start/finish gradient color based on the battery % at the start and end of a step in the journey.
   */
  const getRoutePolyLineFromSteps = useCallback(
    ({ steps, pathIndices }): RouteLineGeoJson =>
      steps
        .map((step) => ({
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {
                id: `route-step-${step.id}`,
                departureBatteryPerc: step.departurePerc,
                arrivalBatteryPerc: step.arrivalPerc,
              },
              geometry: {
                type: 'LineString',
                coordinates: step?.path?.map((path) => [
                  path[pathIndices.lon],
                  path[pathIndices.lat],
                ]),
              },
            },
          ],
        }))
        .filter(({ features: [{ geometry }] }) => geometry.coordinates),
    [],
  )

  /**
   * Sometimes the API will return a 200 response
   * but with no route data attached, only a status.
   * In this case we need to make sure we handle it.
   */
  const getRouteSummary = useCallback(
    ({ route, status }): RouteSummary => {
      const { totalDist, totalChargeDuration, totalDriveDuration } = route

      const stopCount = getRouteMarkers({ steps: route.steps }).length - 2

      return {
        status: status as RouteStatusEnum,
        totalDistMeters: totalDist,
        totalDriveDurationSeconds: totalDriveDuration,
        totalChargeDurationSeconds: totalChargeDuration,
        stopCount,
      }
    },
    [getRouteMarkers],
  )

  /**
   * @param waypoints
   * @returns Sanitised waypoints data
   *
   * Sending empty strings as a waypoint address to the API causes
   * some pretty wild behaviour!
   * If the user leaves empty waypoints in the route planner form
   * we are filtering them out here.
   */
  const sanitiseWaypoints = (waypoints: WaypointDictionary): RouteDestinationAddress[] =>
    Object.keys(waypoints)
      .map((key) => ({
        address: waypoints[key],
      }))
      .filter((waypoint) => waypoint.address)

  const clearRoutes = useCallback(() => {
    setRoutes(null)
    clearActiveMarker()
    toggleLocationDetailsPanel({ open: false })
  }, [clearActiveMarker, toggleLocationDetailsPanel])

  /**
   * Transforms a raw API response to the data we need to
   * display a route to the user.
   * @param planResponse - response data from Internios API
   * @returns a dictionary object that contains all the route data required to render
   * routes returned by the API to the map.
   */
  const buildRoutesDictionaryFromPlanResponse = useCallback(
    (planResponse: PlanResponse): RoutesDictionary => {
      const { pathIndices } = planResponse.result
      const { status } = planResponse

      const routeData: RoutesDictionary = {}

      planResponse?.result?.routes.forEach((route) => {
        const routeid = uuid()
        const markers = getRouteMarkers({ steps: route.steps })
        const polyLine = getRoutePolyLineFromSteps({ steps: route.steps, pathIndices })
        const summary = getRouteSummary({ route, status })

        routeData[routeid] = {
          id: routeid,
          polyLine,
          markers,
          summary,
          steps: route.steps,
        }
      })

      return routeData
    },
    [getRouteMarkers, getRoutePolyLineFromSteps, getRouteSummary],
  )

  const getDestinationAddresses = useCallback(
    (formFields: RouteFormFields): RouteDestinationAddress[] => {
      const startPointData = { address: formFields.startingLocation }
      const sanitisedWaypointData = sanitiseWaypoints(formFields.waypoints)
      const finalDestinationData = { address: formFields.destination }
      return [startPointData, ...sanitisedWaypointData, finalDestinationData]
    },
    [],
  )

  const sendRouteSubmitAnalytics = useCallback((formFields: RouteFormFields) => {
    const startingLocationSplit = formFields.startingLocation.split(',')
    const destinationSplit = formFields.destination.split(',')

    const startingCountry = startingLocationSplit[startingLocationSplit.length - 1]
    const destinationCountry = destinationSplit[destinationSplit.length - 1]

    const isInternationalRoute = startingCountry !== destinationCountry
    const waypointCounter = Object.keys(formFields.waypoints).length
    GTM.submitRoutePlanner({ ...formFields, isInternationalRoute, waypointCounter })
  }, [])

  /**
   * @param formFields route planner form fields
   * Handles the route plan form submit and converts the returned
   * data into a format usable by Mapbox.
   * Marker and Route summary data are also set to state here.
   */
  const handleNewRouteSubmit = useCallback(
    async (formFields: RouteFormFields) => {
      setActiveRouteId(null)
      setFormfields(formFields)
      clearSearchMarker()
      clearRoutes()
      clearActiveMarker()
      toggleRouteDetailsPanel({ open: false })
      sendRouteSubmitAnalytics(formFields)

      try {
        const destinationAddresses = getDestinationAddresses(formFields)

        const routePlan = await getRoutePlan({
          variables: {
            routeParams: {
              destinations: destinationAddresses,
              arrivalSocPerc: formFields.destinationBatteryPerc,
              initialSocPerc: formFields.startingBatteryPerc,
              vehicleId: formFields.selectedVehicle.vehicle.pk,
              excludeNonEjnLocations: formFields.ejLocationsOnly,
              pathPolyline: true,
              pathSteps: true,
              allowBorder: true,
              allowFerry: true,
              allowMotorway: true,
              allowToll: !formFields.avoidTolls,
              findAlts: true,
            },
          },
        })

        const planResponseHasRoutes =
          routePlan?.data?.routePlan?.planResponse?.result?.routes.length > 0

        if (planResponseHasRoutes) {
          const routeData = buildRoutesDictionaryFromPlanResponse(
            routePlan?.data?.routePlan?.planResponse,
          )
          const routeCount = Object.keys(routeData).length

          const nextRoutes = {
            data: routeData,
            routeCount,
            startPoint: formFields.startingLocation,
            finalDestination: formFields.destination,
          }
          setRoutes(nextRoutes)

          setRouteDetailsPanelScreen(RouteDetailStagesEnum.SELECTING_ROUTE)
          toggleRouteDetailsPanel({ open: true })
          closeModal()

          /**
           * We always want to show the fastest route by default.
           * Unless we only get one route returned. Then we'll skip
           * the route selection screen entirely.
           */
          if (routeCount === 1) {
            setRouteDetailsPanelScreen(RouteDetailStagesEnum.VIEWING_ROUTE_STEPS)
            setActiveRouteId(Object.keys(routeData)[0])
          } else {
            const fastestRouteId = getFastestRouteId(nextRoutes)
            setActiveRouteId(fastestRouteId)
          }
        } else if (!planResponseHasRoutes) {
          clearRoutes()
          setActiveModalScreen(ModalScreenNames.ROUTE_PLAN_ERROR_SCREEN)()

          setError(routePlan.data?.routePlan?.planResponse?.status as RouteStatus)
          Sentry.captureMessage(
            `RoutePlanner, no route returned. Status => ${routePlan.data?.routePlan?.planResponse?.status}`,
            (scope) => scope.setExtras(formFields as any),
          )
          toggleRouteDetailsPanel({ open: false })
        }
      } catch (err) {
        // TODO: handle errors from our API and display them in the route error modal.
        Sentry.captureException(err, (scope) => scope.setExtras(formFields as any))
        setActiveModalScreen(ModalScreenNames.ROUTE_PLAN_ERROR_SCREEN)()
        toggleRouteDetailsPanel({ open: false })
      }
    },
    [
      sendRouteSubmitAnalytics,
      buildRoutesDictionaryFromPlanResponse,
      clearActiveMarker,
      clearRoutes,
      clearSearchMarker,
      closeModal,
      getDestinationAddresses,
      getRoutePlan,
      setActiveModalScreen,
    ],
  )

  const selectActiveRoute = useCallback((routeId: string) => setActiveRouteId(routeId), [])

  const state = useMemo(
    () => ({
      error,
      routes,
      activeRouteId,
      routeDetailsPanelScreen,
      routeFormFields: formfields,
      loading: routePlanMutation.loading,
      routeDetailsPanelOpen,
    }),
    [
      error,
      routes,
      activeRouteId,
      routeDetailsPanelScreen,
      formfields,
      routePlanMutation.loading,
      routeDetailsPanelOpen,
    ],
  )

  const handlers = useMemo(
    () => ({
      clearRoutes,
      setRouteDetailsPanelScreen,
      handleNewRouteSubmit,
      toggleRouteDetailsPanel,
      selectActiveRoute,
      getRouteMarkers,
    }),
    [clearRoutes, handleNewRouteSubmit, selectActiveRoute, getRouteMarkers],
  )

  return [state, handlers]
}

const RouteContext = createContext<UseRouteContext>(null)

export const useRoute = () => {
  const context = useContext(RouteContext)
  if (!context) {
    throw new Error(
      `useRoute() hook cannot be used outside the context of <RouteContextProvider/> `,
    )
  }
  return context
}

export const RouteContextProvider = ({ children }) => {
  const context: UseRouteContext = useRouteProvider()
  return <RouteContext.Provider value={context}>{children}</RouteContext.Provider>
}
