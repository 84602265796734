import { tw } from '@electro/shared/utils/tailwind-merge'
import { forwardRef, ButtonHTMLAttributes, ReactNode, ForwardedRef, MouseEventHandler } from 'react'
import { rippleEffect } from '../Button/buttonRippleEffect'

export type IconButtonSize = 'xs' | 'sm'
export type IconButtonType = 'button' | 'submit'

export interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: MouseEventHandler<HTMLButtonElement>
  children: ReactNode | string
  size?: IconButtonSize
  type?: IconButtonType
  className?: string
  disabled?: boolean
}

const styles = {
  button: {
    root: 'flex items-center justify-center relative rounded-full overflow-hidden z-10 text-white hover:text-tertiary',
  },
  size: {
    xs: 'w-6 h-6 [&_svg]:h-4 [&_svg]:w-4',
    sm: 'w-8 h-8 [&_svg]:h-6 [&_svg]:w-6',
  },
}

export const IconButton = forwardRef(
  (
    {
      children,
      className,
      size = 'sm',
      type = 'button',
      disabled = false,
      onClick = () => {},
      ...rest
    }: IconButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => (
    <button
      ref={ref}
      onClick={(e) => {
        onClick(e)
        rippleEffect(e)
      }}
      disabled={disabled}
      type={type}
      className={tw({
        [styles.button.root]: true,
        [styles.size.xs]: size === 'xs',
        [styles.size.sm]: size === 'sm',
        [className]: !!className,
      })}
      {...rest}
    >
      {children}
    </button>
  ),
)
