export enum SidebarPanels {
  // VISIBLE
  MAP = 'map',
  FILTERS = 'filters',
  ROUTE_PLANNER = 'route-planner',
  MY_EVS = 'myEVs',
  FEEDBACK = 'feedback',
  KEY = 'key',

  // HIDDEN
  ICON_NAV_LABELS = 'icon-nav-labels',
  ELECTROVERSE_FEATURES = 'electroverse-features',
  OPERATOR_LIST = 'operator-list',
}
export type SidebarPanelsType = `${SidebarPanels}`

export interface DefaultSidebarPanelsType {
  visible: SidebarPanelsType[]
  disabled: SidebarPanelsType[]
  hidden: SidebarPanelsType[]
}

export const DEFAULT_SIDEBAR_PANELS: DefaultSidebarPanelsType = {
  visible: [SidebarPanels.MAP],
  disabled: [SidebarPanels.ROUTE_PLANNER, SidebarPanels.MY_EVS],
  hidden: [SidebarPanels.ELECTROVERSE_FEATURES],
}

export const EMBED_HIDDEN_PANELS = [
  SidebarPanels.ROUTE_PLANNER,
  SidebarPanels.MY_EVS,
  SidebarPanels.FEEDBACK,
]
