import { RoutePathsData } from '@electro/consumersite/src/components/Map/types'

/**
 * Function to return the id of the fastest route plan.
 * @param routes Routes summary object containintg a Dictionary of routes with the route Id as key.
 * @returns string containing the Id of the fastest route including charge time
 */
export default function getFastestRouteId(routes: RoutePathsData): string {
  return Object.values(routes.data).reduce((prev, curr) =>
    prev.summary.totalChargeDurationSeconds + prev.summary.totalDriveDurationSeconds <
    curr.summary.totalChargeDurationSeconds + curr.summary.totalDriveDurationSeconds
      ? prev
      : curr,
  ).id
}
