export enum EnvEnum {
  PRODUCTION = 'production',
  PREVIEW = 'preview',
  DEVELOPMENT = 'development',
  TEST = 'test',
}

export type EnvType = `${EnvEnum}`

const isEnv = (env: EnvType): boolean => {
  if (process.env.NODE_ENV === EnvEnum.TEST) return true
  return [process.env.NEXT_PUBLIC_VERCEL_ENV, process.env.NODE_ENV].some((envVar) => envVar === env)
}

export const testEnv = isEnv(EnvEnum.TEST)
export const prodEnv = isEnv(EnvEnum.PRODUCTION)
export const devEnv = isEnv(EnvEnum.DEVELOPMENT)
export const previewEnv = isEnv(EnvEnum.PREVIEW)
export const devOrPreviewEnv = devEnv || previewEnv
