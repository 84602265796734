export const BatteryIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} fill="none" viewBox="0 0 18 12">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.158 1.421H1.895a.474.474 0 0 0-.474.474v7.579c0 .261.212.473.474.473h13.263a.474.474 0 0 0 .474-.473v-7.58a.474.474 0 0 0-.474-.473ZM1.895 0A1.895 1.895 0 0 0 0 1.895v7.579c0 1.046.848 1.894 1.895 1.894h13.263a1.895 1.895 0 0 0 1.894-1.894v-7.58A1.895 1.895 0 0 0 15.158 0H1.895Z"
      fill="currentColor"
    />
    <path
      d="M16.105 4.737a.947.947 0 0 1 1.895 0v1.895a.947.947 0 0 1-1.895 0V4.737ZM1.895 2.842c0-.523.424-.947.947-.947h2.842v7.579H2.842a.947.947 0 0 1-.947-.948V2.842ZM6.158 1.895h3.79v7.579h-3.79v-7.58ZM10.421 1.895h1.895v7.579H10.42v-7.58Z"
      fill="currentColor"
    />
  </svg>
)
