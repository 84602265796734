import { addSeconds, intervalToDuration, Duration } from 'date-fns'

export default function durationFromSeconds(seconds: number): Duration {
  if (!seconds) return null
  const startDate = new Date(0)
  const endDate = addSeconds(new Date(0), seconds)
  const duration = intervalToDuration({
    start: startDate,
    end: endDate,
  })
  return duration
}
