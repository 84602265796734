import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client'
import USER_VEHICLES_QUERY from '@electro/consumersite/src/graphql/userVehiclesQuery.graphql'

import { Query } from '@electro/consumersite/generated/graphql'

export type FetchUserVehicles = Pick<Query, 'userVehicles' | 'connectorStandardGroups'>
type UseFetchUserVehicles = QueryResult<FetchUserVehicles>

export function useFetchUserVehicles(
  options?: QueryHookOptions<FetchUserVehicles>,
): UseFetchUserVehicles {
  const KrakenAccountsQuery = useQuery<FetchUserVehicles>(USER_VEHICLES_QUERY, options)

  return KrakenAccountsQuery
}
