import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { MinusIcon } from '@heroicons/react/24/outline'

import { Button, IconButton, SwitchToggle } from '@electro/shared-ui-components'
import { BatteryChargeSlider } from '@electro/consumersite/src/components/Map/components/RoutePlanner/components'
import { UserEvsList } from '@electro/consumersite/src/components/Map/components'

import { RouteFormFields } from '@electro/consumersite/src/components/Map/types'
import { useFetchUserVehicles } from '@electro/consumersite/src/services'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

interface RoutePlannerFormProps {
  onSubmit: (fields: RouteFormFields) => void
  formFields: RouteFormFields
  children: ReactNode | ReactNode[]
}

const routeFormValidationSchema = Yup.object().shape({
  selectedVehicle: Yup.object()
    .shape({
      vehicle: Yup.object().shape({
        isHybrid: Yup.boolean().oneOf([false], 'map.route_planner.validation_error.no_hybrids'),
      }),
    })
    .required('map.route_planner.validation_error.no_vehicle')
    .nullable(),
  startingLocation: Yup.string()
    .required('map.route_planner.validation_error.starting_location')
    .nullable(),
  destination: Yup.string().required('map.route_planner.validation_error.destination').nullable(),
})

const RoutePlannerFormContext = createContext(null)

const RoutePlannerForm = ({ formFields, children, onSubmit }: RoutePlannerFormProps) => {
  const formik = useFormik({
    initialValues: {
      selectedVehicle: formFields.selectedVehicle,
      startingLocation: formFields.startingLocation,
      waypoints: formFields.waypoints,
      destination: formFields.destination,
      startingBatteryPerc: formFields.startingBatteryPerc,
      destinationBatteryPerc: formFields.destinationBatteryPerc,
      ejLocationsOnly: formFields.ejLocationsOnly,
      avoidTolls: formFields.avoidTolls,
    },
    validationSchema: routeFormValidationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit,
  })

  const [waypointsAddedCount, setWaypointsAddedCount] = useState<number>(1)

  const incrementCount = useCallback(() => setWaypointsAddedCount((oldCount) => oldCount + 1), [])
  const validateOnBlur = formik.submitCount > 0 ? formik.handleBlur : null

  const { data } = useFetchUserVehicles({ fetchPolicy: 'cache-only' })

  /**
   * We want to set the selected vehicle to the form once we have the
   * user vehicle data from the API.
   */
  useEffect(() => {
    if (data?.userVehicles?.edges?.length === 1) {
      const [{ node: vehicle }] = data.userVehicles.edges
      formik.setFieldValue('selectedVehicle', vehicle)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  /**
   * On mount we want to check if we already have waypoints and add set the count
   * to memory. If we don't do this the user will need to click multiple times to add a new
   * waypoint if there are already waypoints in a prepopulated route.
   */
  useEffect(() => {
    const waypointCount = Object.keys(formFields.waypoints).length
    if (waypointCount > 0) {
      setWaypointsAddedCount(waypointCount + 1)
    }
  }, [formFields])

  const context = useMemo(
    () => ({
      incrementCount,
      validateOnBlur,
      formik,
      waypointsAddedCount,
    }),
    [formik, incrementCount, validateOnBlur, waypointsAddedCount],
  )

  return (
    <RoutePlannerFormContext.Provider value={context}>{children}</RoutePlannerFormContext.Provider>
  )
}

const Fields = () => {
  const { t } = useTranslation('common')
  const router = useRouter()
  const { waypointsAddedCount, formik, validateOnBlur, incrementCount } =
    useContext(RoutePlannerFormContext)
  return (
    <form onSubmit={formik.handleSubmit} onBlur={validateOnBlur} id="route-planner-form">
      <div data-testid="select-ev-section">
        <p className="mb-2 text-sm">{t('map.route_planner.label.select_vehicle')}</p>
        <UserEvsList
          onSelect={({ vehicle }) =>
            () =>
              formik.setFieldValue('selectedVehicle', vehicle)}
        />
        <Button variant="outline" size="xs" onClick={() => router.push('/user/account/vehicles')}>
          {t('filter_manage_evs')}
        </Button>
        {formik.errors.selectedVehicle && (
          <div className="text-action-danger text-sm">
            {t(
              formik.errors?.selectedVehicle?.vehicle?.isHybrid
                ? formik.errors.selectedVehicle.vehicle?.isHybrid
                : formik.errors.selectedVehicle,
            )}
          </div>
        )}
      </div>
      <div className="mt-6 mb-2" data-testid="starting-point-section">
        {/* <LocationInput
          initialSearchValue={formik.values.startingLocation}
          value={formik.values.startingLocation}
          errorMessage={t(formik.errors.startingLocation)}
          name="starting-point"
          label={t('map.route_planner.search.starting_location')}
          placeholder="E.g. London"
          onReset={() => formik.setFieldValue('startingLocation', '')}
          onSelect={(place) => formik.setFieldValue('startingLocation', place.placeName)}
        /> */}
        <BatteryChargeSlider
          initialValue={formik.values.startingBatteryPerc}
          onChange={(value) => formik.setFieldValue('startingBatteryPerc', value)}
          label={t('route_planning_start_charge')}
          name="startingBatteryPerc"
          max={100}
          min={15}
        />
      </div>
      <div className="mt-6 mb-2" data-testid="waypoints-section">
        {Object.keys(formik.values.waypoints).map((waypointId: string, index: number) => (
          <div
            data-testid={`waypoint-${waypointId}`}
            className="flex w-full items-center"
            key={waypointId}
          >
            <div className="flex-grow">
              {/* <LocationInput
                name={`waypoint-${waypointId}`}
                label={`${t('map.route_planner.label.waypoint')} ${index + 1}`}
                initialSearchValue={formik.values.waypoints?.[waypointId]}
                value={formik.values.waypoints?.[waypointId]}
                placeholder="E.g. Amsterdam"
                onReset={() => {
                  formik.setFieldValue('waypoints', {
                    ...formik.values.waypoints,
                    [waypointId]: {},
                  })
                }}
                onSelect={(place) =>
                  formik.setFieldValue('waypoints', {
                    ...formik.values.waypoints,
                    [waypointId]: place.placeName,
                  })
                }
              /> */}
            </div>
            <IconButton
              data-testid={`delete-waypoint-${waypointId}`}
              onClick={() => {
                const nextWaypoints = Object.keys(formik.values.waypoints).reduce((acc, key) => {
                  if (key !== waypointId) {
                    acc[key] = formik.values.waypoints[key]
                  }
                  return acc
                }, {})
                formik.setFieldValue('waypoints', nextWaypoints)
              }}
              className="ml-2 -mb-1"
            >
              <MinusIcon className="w-6 h-6 bg-base border border-secondary rounded-full p-1 hover:bg-action-danger hover:border-white hover:text-white" />
            </IconButton>
          </div>
        ))}
        {Object.keys(formik.values.waypoints).length <= 4 && (
          <Button
            onClick={() => {
              formik.setFieldValue('waypoints', {
                ...formik.values.waypoints,
                [waypointsAddedCount]: '',
              })
              incrementCount()
            }}
            variant="outline"
            size="xs"
          >
            {t('route_planning_add_a_waypoint')}
          </Button>
        )}
      </div>
      <div className="mt-6 mb-2" data-testid="destination-section">
        {/* <LocationInput
          initialSearchValue={formik.values.destination}
          value={formik.values.destination}
          errorMessage={t(formik.errors.destination)}
          name="destination"
          label={t('route_planning_choose_destination')}
          placeholder="E.g. Paris"
          onReset={() => formik.setFieldValue('destination', '')}
          onSelect={(place) => formik.setFieldValue('destination', place.placeName)}
        /> */}
        <BatteryChargeSlider
          initialValue={formik.values.destinationBatteryPerc}
          onChange={(value) => formik.setFieldValue('destinationBatteryPerc', value)}
          label={t('route_planning_end_charge')}
          name="destinationBatteryPerc"
          max={100}
          min={0}
        />
      </div>
      <div className="flex items-center my-8">
        <div className="flex-grow ">{t('map.route_planner.toggles.avoid_tolls')}</div>
        <SwitchToggle
          checked={formik.values.avoidTolls}
          onChange={() => formik.setFieldValue('avoidTolls', !formik.values.avoidTolls)}
        />
      </div>
      <div className="flex items-center my-8">
        <div className="flex-grow ">{t('map.route_planner.toggles.electroverse_only')}</div>
        <SwitchToggle
          checked={formik.values.ejLocationsOnly}
          onChange={() => formik.setFieldValue('ejLocationsOnly', !formik.values.ejLocationsOnly)}
        />
      </div>
    </form>
  )
}

const SubmitButton = () => {
  const { t } = useTranslation('common')
  return (
    <Button type="submit" form="route-planner-form" fullWidth>
      {t('map.route_planner.button.calculate_route')}
    </Button>
  )
}

RoutePlannerForm.Fields = Fields
RoutePlannerForm.SubmitButton = SubmitButton

export { RoutePlannerForm }
