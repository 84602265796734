import React, { HTMLAttributes } from 'react'
import {
  FiltersSidebarIcon,
  FiltersPanel,
  RoutePlannerPanel,
  MapSidebarIcon,
  RoutePlannerSidebarIcon,
  KeySidebarIcon,
  KeyPanel,
  MyEVsSidebarIcon,
  MyEVsPanel,
  FeedbackSidebarIcon,
  FeedbackPanel,
  ElectroverseFeaturesPanel,
  OperatorListPanel,
  SidebarNavLabelsPanel,
  ElectroverseFeaturesIcon,
  SidebarNavLabelsIcon,
} from '@electro/consumersite/src/components/Map/components/MapSidebar/panels'
import { SidebarNav } from '@electro/consumersite/src/components/Map/components/MapSidebar/components/SidebarNav'

const SidebarContainer = ({ children, className }: HTMLAttributes<HTMLDivElement>) => (
  <div className={className} data-testid="map-sidebar">
    {children}
  </div>
)

/** Map Sidebar Core */
SidebarContainer.SidebarNav = SidebarNav
SidebarContainer.SidebarNavLabelsIcon = SidebarNavLabelsIcon
SidebarContainer.SidebarNavLabelsPanel = SidebarNavLabelsPanel

/** Map Sidebar Icons */
SidebarContainer.MapIcon = MapSidebarIcon
SidebarContainer.FiltersIcon = FiltersSidebarIcon
SidebarContainer.RoutePlannerIcon = RoutePlannerSidebarIcon
SidebarContainer.MyEVsIcon = MyEVsSidebarIcon
SidebarContainer.ElectroverseFeaturesIcon = ElectroverseFeaturesIcon
SidebarContainer.FeedbackIcon = FeedbackSidebarIcon
SidebarContainer.KeyIcon = KeySidebarIcon

/** Map Sidebar Panels */
SidebarContainer.FiltersPanel = FiltersPanel
SidebarContainer.RoutePlannerPanel = RoutePlannerPanel
SidebarContainer.MyEVsPanel = MyEVsPanel
SidebarContainer.FeedbackPanel = FeedbackPanel
SidebarContainer.KeyPanel = KeyPanel

/** Map Sidebar Panels - Hidden */
SidebarContainer.OperatorListPanel = OperatorListPanel
SidebarContainer.ElectroverseFeatures = ElectroverseFeaturesPanel

export { SidebarContainer }
