import { v1 as uuid } from 'uuid'
import useTranslation from 'next-translate/useTranslation'
import { useRoutePlannerForm } from '@electro/consumersite/src/components/Map/hooks'
import { UseMapSearchProvider } from '@electro/consumersite/src/components/Map/hooks/useMapSearch'
import { LocationSearch } from '@electro/consumersite/src/components/Map/components/MapLocationSearch/components/LocationSearch'
import { PlusIcon, MinusIcon } from '@heroicons/react/16/solid'
import { Button } from '@electro/shared-ui-components'
import { Transition } from '@headlessui/react'
import { PlaceDetails } from '@electro/consumersite/src/components/Map/helpers'

const styles = {
  root: 'flex flex-col gap-y-3',
  waypoints: {
    root: 'relative',
    dottedLine: 'absolute w-px h-full py-4 ml-1.5 vertical-dashed-line bg-clip-content',
    searchBox: {
      root: 'flex gap-x-2',
      circle: 'my-auto shrink-0 w-3 h-3 bg-secondary rounded-full',
      minimiseWaypoint: 'absolute right-2.5 mt-3 rounded-full p-0 [&_svg]:w-4 z-[30]',
    },
    waypointButton: '[&_svg]:h-4 text-2xs font-medium ml-6 my-2',
  },
}

export const RoutePlannerLocationSearch = () => {
  const { t } = useTranslation('common')
  const [{ destinations }, { updateDestinations }] = useRoutePlannerForm({})

  const waypointIDs = Object.keys(destinations).filter((key) => !['start', 'end'].includes(key))

  return (
    <div className={styles.root}>
      <div className={styles.waypoints.root}>
        <div className={styles.waypoints.dottedLine} />

        <div>
          <SearchbarMiniComponent
            id="start"
            initialValue={destinations.start}
            placeholder={t('map.route_planner.search.starting_location')}
          />

          {waypointIDs.map((id) => (
            <Transition
              appear
              key={id}
              enter="transition ease-in-out duration-300"
              enterFrom="-translate-x-full"
            >
              <SearchbarMiniComponent
                id={id}
                initialValue={destinations[id]}
                placeholder={t('map.route_planner.search.waypoint')}
                onClose={() => {
                  const { [id]: _, ...updatedDestinations } = destinations
                  updateDestinations(updatedDestinations)
                }}
              />
            </Transition>
          ))}

          <Button
            size="2xs"
            onClick={() => updateDestinations({ ...destinations, [uuid()]: {} as PlaceDetails })}
            className={styles.waypoints.waypointButton}
          >
            <PlusIcon /> {t('map.route_planner.button.add_a_waypoint')}
          </Button>

          <SearchbarMiniComponent
            id="end"
            initialValue={destinations.end}
            placeholder={t('map.route_planner.search.destination')}
          />
        </div>
      </div>
    </div>
  )
}

interface SearchbarMiniComponentProps {
  id: string
  placeholder: string
  initialValue?: PlaceDetails
  onClose?: () => void
}

const SearchbarMiniComponent = ({ onClose, ...props }: SearchbarMiniComponentProps) => (
  <div className={styles.waypoints.searchBox.root}>
    <span className={styles.waypoints.searchBox.circle} />
    <UseMapSearchProvider target="routePlanner" {...props}>
      <LocationSearch />
    </UseMapSearchProvider>

    {onClose ? (
      <Button className={styles.waypoints.searchBox.minimiseWaypoint} onClick={onClose}>
        <MinusIcon />
      </Button>
    ) : null}
  </div>
)
