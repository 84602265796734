import Link from 'next/link'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { LazyIcon } from '@electro/shared/icons'
import { Locales } from '@electro/shared/types/locales'
import { tw } from '@electro/shared/utils/tailwind-merge'
import useTranslation from 'next-translate/useTranslation'
import { OctoRegionSelect } from '@electro/consumersite/src/components/OctoRegionSelect'
import { RestrictToLocales } from '@electro/consumersite/src/components/RestrictToLocales'
import { mapLocaleToCountryDetails } from '@electro/shared/utils/mapLocaleToCountryDetails'
import {
  getActiveHolidaySeason,
  getSeasonalImagePath,
} from '@electro/consumersite/src/helpers/seasonalRendering'
import { EnvelopeIcon } from '@heroicons/react/16/solid'

const styles = {
  footer: {
    root: 'max-w-6xl w-full mx-auto px-2 relative',
    display: tw(
      'p-10 rounded-t-3xl',
      'md:grid grid-cols-2',
      'bg-constantine-silhouette-cropped bg-no-repeat',
      '!bg-[#6018C8] bg-blend-color-dodge', // Hardcoded value specific to the constantine silhouette image
    ),
    externalLinks: 'md:order-2',
    navigation: {
      root: 'md:grid md:grid-cols-2 md:order-1',
    },
    menuItem: {
      root: 'pb-4 last:pb-0',
      link: 'text-white',
    },
    social: {
      root: 'flex gap-3 xs:gap-6 mb-4 md:justify-end',
      link: 'flex mb-4 p-3 bg-white hover:bg-primary rounded-full [&>p]:sr-only',
      icon: 'w-6 h-6 text-[#6018c8]',
    },
    oe: {
      link: 'text-white flex group mb-8',
      icon: 'mr-2 items-center',
    },
    legal: 'text-xs text-center mt-4 md:col-span-2 md:order-3 md:text-right md:pl-64',
    seasonal: 'absolute right-6 bottom-48 md:left-7 md:bottom-0',
  },
}

const GLOBAL_NAV_ITEMS = [
  {
    label: 'nav.footer.item.privacy',
    pathname: '/legal/privacy',
  },
  {
    label: 'nav.footer.item.terms',
    pathname: '/legal/terms',
  },
]
const LOCALE_RESTRICTED_NAV_ITEMS = [
  {
    label: 'nav.footer.item.business_terms',
    pathname: '/legal/business/terms',
  },
]

export enum SocialMediaPlatformsEnum {
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  INSTAGRAM = 'instagram',
  LINKEDIN = 'linkedIn',
  EMAIL = 'email',
}

export interface SocialMediaLinks {
  [SocialMediaPlatformsEnum.INSTAGRAM]?: string
  [SocialMediaPlatformsEnum.TWITTER]?: string
  [SocialMediaPlatformsEnum.FACEBOOK]?: string
  [SocialMediaPlatformsEnum.LINKEDIN]?: string
  [SocialMediaPlatformsEnum.EMAIL]?: string
}

export const Footer = () => {
  const { locale } = useRouter()
  const { t } = useTranslation('common')
  const activeHolidaySeason = getActiveHolidaySeason()

  return (
    <footer className={styles.footer.root} data-testid="footer">
      <div className={styles.footer.display}>
        <div className={styles.footer.externalLinks} data-testid="footer-external-links">
          <div className={styles.footer.social.root} data-testid="social">
            <a
              href={`mailto:${mapLocaleToCountryDetails(locale).supportEmail}`}
              target="_blank"
              rel="noreferrer"
              className={styles.footer.social.link}
              aria-label={t('footer.link.contact_email')}
            >
              <EnvelopeIcon className={styles.footer.social.icon} />
            </a>

            <a
              href="https://www.facebook.com/OctopusElectroverse"
              target="_blank"
              rel="noreferrer"
              className={styles.footer.social.link}
            >
              <p>Facebook</p>
              <LazyIcon name="24/logos/facebook" className={styles.footer.social.icon} />
            </a>

            <a
              href="https://twitter.com/OctopusElectro"
              target="_blank"
              rel="noreferrer"
              className={styles.footer.social.link}
            >
              <p>X (Twitter)</p>
              <LazyIcon name="24/logos/twitter-x" className={styles.footer.social.icon} />
            </a>

            <a
              href="https://www.linkedin.com/company/octopus-electroverse"
              target="_blank"
              rel="noreferrer"
              className={styles.footer.social.link}
            >
              <p>LinkedIn</p>
              <LazyIcon name="24/logos/linkedin" className={styles.footer.social.icon} />
            </a>

            <a
              href="https://www.instagram.com/octopuselectroverse"
              target="_blank"
              rel="noreferrer"
              className={styles.footer.social.link}
            >
              <p>Instagram</p>
              <LazyIcon name="24/logos/instagram" className={styles.footer.social.icon} />
            </a>

            <a
              href="https://www.youtube.com/@OctopusElectroverse"
              target="_blank"
              rel="noreferrer"
              className={styles.footer.social.link}
            >
              <p>YouTube</p>
              <LazyIcon name="24/logos/youtube" className={styles.footer.social.icon} />
            </a>
          </div>
          <div data-testid="oe-link" className="md:flex md:justify-end">
            <OctoRegionSelect>
              <div className="flex -ml-4 mb-4 hover:text-octo-primary-pink">
                <LazyIcon name="24/constantine" className={styles.footer.oe.icon} />
                Octopus Energy
              </div>
            </OctoRegionSelect>
          </div>
        </div>

        <nav data-testid="footer-navigation" className={styles.footer.navigation.root}>
          <ul>
            {GLOBAL_NAV_ITEMS.map((item) => (
              <li key={item.label} className={styles.footer.menuItem.root}>
                <Link legacyBehavior href="/[...slug]" as={item.pathname}>
                  <a className={styles.footer.menuItem.link}>{t(item.label)}</a>
                </Link>
              </li>
            ))}

            <RestrictToLocales locales={[Locales.EN]}>
              {LOCALE_RESTRICTED_NAV_ITEMS.map((item) => (
                <li key={item.label} className={styles.footer.menuItem.root}>
                  <Link legacyBehavior href="/[...slug]" as={item.pathname}>
                    <a className={styles.footer.menuItem.link}>{t(item.label)}</a>
                  </Link>
                </li>
              ))}
            </RestrictToLocales>
          </ul>
        </nav>
        <div className={styles.footer.legal} data-testid="legal">
          <p>
            <span>
              Octopus Electroverse Ltd is a company registered in England and Wales. Registered
              office: 15274675. Registered office: 5th Floor, UK House, 164-182 Oxford Street,
              London, W1D 1NN.
            </span>{' '}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://octopus.energy/policies/modern-slavery-statement/"
            >
              Modern slavery statement
            </a>
            .
          </p>
        </div>
      </div>
      {activeHolidaySeason ? (
        <Image
          src={getSeasonalImagePath('collection-of-items', activeHolidaySeason)}
          width={174}
          height={107}
          alt="Collection of seasonal items!"
          className={styles.footer.seasonal}
        />
      ) : null}
    </footer>
  )
}
