import Link from 'next/link'
import Image from 'next/image'
import { Button } from '@electro/shared-ui-components'
import { tw } from '@electro/shared/utils/tailwind-merge'
import useTranslation from 'next-translate/useTranslation'
import { useUserVehicles } from '@electro/consumersite/src/hooks'
import {
  MyEVsActiveVehicle,
  MyEVsVehicleButton,
} from '@electro/consumersite/src/components/Map/components/MapSidebar/panels/MyEVs/components'

const styles = {
  root: 'flex flex-col flex-grow justify-between',
  noVehicles: {
    root: 'flex flex-col items-center mx-1',
    image: 'w-2/3 mx-auto my-8',
    text: 'text-center override-list-style',
    tailoredExperience: 'text-sm font-light mb-0',
    list: tw(
      'mx-4 !mt-1 pt-2 pb-1 !pl-10 text-left',
      'border-2 border-primary rounded-lg',
      'shadow-primary shadow-md',
    ),
    multipleEVs: 'text-sm font-light mt-6',
  },
  inactiveVehicles: 'flex flex-col gap-y-2 mt-8 mb-6 mx-1',
  manageEVsButton: 'w-full no-underline',
}

export const MyEVsPanelContent = () => {
  const { t } = useTranslation('common')
  const [{ userVehicles, inactiveVehicles }, { updateActiveVehicle }] = useUserVehicles()

  return (
    <>
      {userVehicles.length === 0 ? (
        <div className={styles.noVehicles.root}>
          <Image
            className={styles.noVehicles.image}
            src="/images/octopus-car.svg"
            alt="Image of an octopus themed car"
            width={770}
            height={370}
          />

          <div className={styles.noVehicles.text}>
            <p>{t('map.my_evs.no_vehicles.add_vehicle')}</p>

            <p className={styles.noVehicles.tailoredExperience}>
              {t('map.my_evs.no_vehicles.tailored_experience')}
            </p>

            <ul className={styles.noVehicles.list}>
              <li>
                <p>{t('map.electroverse_features.list.filter_by_vehicle')}</p>
              </li>
              <li>
                <p>{t('map.electroverse_features.list.route_planner')}</p>
              </li>
            </ul>

            <p className={styles.noVehicles.multipleEVs}>
              {t('map.my_evs.no_vehicles.multiple_evs')}
            </p>
          </div>
        </div>
      ) : null}

      {userVehicles.length > 0 ? (
        <div>
          <MyEVsActiveVehicle />

          <div className={styles.inactiveVehicles}>
            {inactiveVehicles.map((vehicle) => (
              <MyEVsVehicleButton
                key={vehicle.pk}
                vehicle={vehicle}
                onClick={() => updateActiveVehicle(vehicle)}
              />
            ))}
          </div>
        </div>
      ) : null}

      <Link href="/user/account/vehicles" className={styles.manageEVsButton}>
        <Button size="sm" fullWidth>
          {t('map.my_evs.manage')}
        </Button>
      </Link>
    </>
  )
}
