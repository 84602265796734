import { Source, Layer } from 'react-map-gl'
import { useMapFilters, useRoute } from '@electro/consumersite/src/components/Map/hooks'
import {
  presentationScreenAllLocationsLayerStyle,
  ejLocationLayerStyle,
  nonEjLocationCircleLayerStyle,
} from '@electro/consumersite/src/components/Map/components/MapLayers'

/**
 * <AllLocationsLayer/>
 * differs from the <LocationsLayer/> in that it will show all locations
 * on the map. This is because it is not using elastic search for filtering.
 * This means it will not hit a hard limit of 10k results per tile.
 * It also means this layer cannot be filtered.
 *
 * It's current use case is display screens and wall boards.
 */
export const AllLocationsLayer = () => {
  const [{ routes }] = useRoute()
  const [{ elasticSearchParams }] = useMapFilters()

  return !routes ? (
    <Source
      id="charging_locations"
      type="vector"
      tiles={[
        `${process.env.NEXT_PUBLIC_EJN_API_REST_ENDPOINT}locations/tiles/presentation/{z}/{x}/{y}?${elasticSearchParams}`,
      ]}
    >
      <Layer {...presentationScreenAllLocationsLayerStyle({ maxzoom: 7.0 })} />
      <Layer {...nonEjLocationCircleLayerStyle({ minzoom: 7.0 })} />
      <Layer {...ejLocationLayerStyle({ minzoom: 7.0 })} />
    </Source>
  ) : null
}
