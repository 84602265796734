import { ElementType, ReactNode } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { Menu as MenuHeadless } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { ButtonProps } from '@electro/shared-ui-components'
import { useMenu, MenuProvider } from './MenuContext'

type ColorVariants = 'light' | 'dark'
interface MenuProps {
  children: ReactNode | ReactNode[]
}
interface MenuItemListProps {
  children: ReactNode | ReactNode[]
  variant?: ColorVariants
  className?: string
}

interface MenuButtonProps extends ButtonProps {
  children: ReactNode | ReactNode[]
  chevron?: boolean
  chevronActiveClassName?: string
  activeClassName?: string
  noPadding?: boolean
  as?: ElementType
  onClick?: () => void
}

const styles = {
  menu: {
    root: 'inline-block text-left',
  },
  menuItemList: {
    root: tw(
      'absolute w-auto mt-2 rounded-lg shadow-xl z-10',
      'ring-1 ring-opacity-50 focus:outline-none',
      'px-1 py-1',
      'disabled:opacity-50',
    ),
    light: 'bg-white text-base-dark ring-base-dark/50',
    dark: 'bg-base-dark text-white ring-secondary dark ',
  },
  menuItem: {
    root: 'group flex rounded-lg items-center w-full px-3 py-2 whitespace-nowrap text-sm disabled:cursor-not-allowed disabled:opacity-50',
    active: 'bg-secondary text-white',
  },
  button: {
    root: 'inline-flex items-center justify-center w-full rounded-lg text-white text-base font-medium',
    padding: 'px-4 py-2',
    active: 'text-tertiary',
    chevron: 'w-5 h-5 ml-2 -mr-1 text-white',
    chevronActive: 'w-5 h-5 ml-2 -mr-1 text-tertiary',
  },
}

const MenuItem = ({ children, onSelect, disabled = false }) => (
  <MenuHeadless.Item>
    {({ active }) => (
      <button
        disabled={disabled}
        onClick={onSelect}
        className={tw({
          [styles.menuItem.root]: true,
          [styles.menuItem.active]: active,
        })}
      >
        {children}
      </button>
    )}
  </MenuHeadless.Item>
)

const MenuItemList = ({ children, variant = 'light', className }: MenuItemListProps) => {
  const { setPortalRef, portalStyles, portalAttributes } = useMenu()

  return (
    <MenuHeadless.Items
      className={tw({
        [styles.menuItemList.root]: true,
        [styles.menuItemList.light]: variant === 'light',
        [styles.menuItemList.dark]: variant === 'dark',
        [className]: !!className,
      })}
      ref={setPortalRef}
      style={portalStyles.popper}
      {...portalAttributes.popper}
    >
      <div data-testid="menu-items">{children}</div>
    </MenuHeadless.Items>
  )
}

const Button = ({
  children,
  chevron = true,
  className,
  disabled = false,
  noPadding = false,
  activeClassName,
  chevronActiveClassName,
  as = 'button',
  ...restProps
}: MenuButtonProps) => {
  const { setButtonRef } = useMenu()

  return (
    <MenuHeadless.Button
      ref={setButtonRef}
      as={as}
      {...restProps}
      disabled={disabled}
      className={tw({
        [styles.button.root]: true,
        [className]: !!className,
        [styles.button.padding]: !noPadding,
      })}
    >
      {({ open }) => (
        <>
          <div
            className={tw({
              [styles.button.active]: open,
              [activeClassName]: open && !!activeClassName,
            })}
          >
            {children}
          </div>
          {!open && chevron ? (
            <ChevronDownIcon className={styles.button.chevron} aria-hidden="true" />
          ) : null}
          {open && chevron ? (
            <ChevronUpIcon
              className={tw({
                [styles.button.chevronActive]: true,
                [chevronActiveClassName]: !!chevronActiveClassName,
              })}
              aria-hidden="true"
            />
          ) : null}
        </>
      )}
    </MenuHeadless.Button>
  )
}

const Menu = ({ children }: MenuProps) => (
  <MenuProvider>
    <MenuHeadless as="div" className={styles.menu.root}>
      {children}
    </MenuHeadless>
  </MenuProvider>
)

Menu.MenuItem = MenuItem
Menu.MenuItemList = MenuItemList
Menu.Button = Button

export { Menu }
