import { Button, IconButton, TableCell, TableRow } from '@electro/shared-ui-components'
import { ComponentType, useState } from 'react'
import { ChevronDownIcon, ChevronUpIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { DataGridCell } from '..'
import { DataGridColumnDef, DataGridRowDef } from '../..'
import { useDataGrid } from '../../hooks'

interface DetailPanelProps {
  row: DataGridRowDef
}

interface DataGridRowProps {
  row: DataGridRowDef
  columns: DataGridColumnDef[]
  detailPanel?: ComponentType<DetailPanelProps>
}

const styles = {
  row: {
    open: 'bg-secondary-dark',
    bottomBorderHidden: '[&>td]:!border-b-0',
    clickable: 'cursor-pointer',
  },
  detailPanelRow: {
    desktop: 'bg-secondary-dark lg:relative lg:table-row',
    mobile: 'fixed top-0 right-0 h-full w-screen overflow-y-auto z-40 flex',
    closeButtonWrapper: 'flex justify-end pb-2 lg:hidden',
  },
}

export const DataGridRow = ({ row, columns, detailPanel: DetailPanel }: DataGridRowProps) => {
  const [open, setOpen] = useState(false)
  const [{ hasDetailPanel }, { handleRowClick }] = useDataGrid()
  const colSpan = columns.length + 1 // we add 1 for the collapse button header

  const handleCollapse = () => setOpen((prev) => !prev)

  const HandleOnClick = (event) => {
    handleRowClick(row, event)

    if (hasDetailPanel) {
      handleCollapse()
    }
  }

  return (
    <>
      <TableRow
        key={row.key}
        onClick={HandleOnClick}
        data-testid="data-grid-row"
        className={tw({
          [styles.row.open]: open,
          [styles.row.bottomBorderHidden]: open,
          [styles.row.clickable]: !!handleRowClick,
        })}
      >
        {columns.map((column: DataGridColumnDef) => (
          <DataGridCell key={column.field} row={row} column={column} />
        ))}

        {hasDetailPanel && (
          <TableCell className="hidden lg:flex">
            <Button
              data-testid="data-grid-row-collapse-button"
              aria-label="Expand data grid row"
              variant="naked"
              size="xs"
            >
              {open ? (
                <ChevronUpIcon className="w-5 h-5" />
              ) : (
                <ChevronDownIcon className="w-5 h-5" />
              )}
            </Button>
          </TableCell>
        )}
      </TableRow>

      {open && (
        <TableRow
          data-testid="data-grid-row-detail-panel"
          className={tw(styles.detailPanelRow.desktop, styles.detailPanelRow.mobile)}
        >
          <TableCell colSpan={colSpan} className="flex-1">
            <div className={styles.detailPanelRow.closeButtonWrapper}>
              <IconButton aria-label="Close detail panel" onClick={handleCollapse}>
                <XMarkIcon className="w-6 h-6" />
              </IconButton>
            </div>

            <DetailPanel row={row} />
          </TableCell>
        </TableRow>
      )}
    </>
  )
}
