/**
 * Feature flags that are enabled/disabled based on environment variables
 * Ensure that:
 * 1. Environment variables are prefixed with NEXT_PUBLIC_ to make it available in the client.
 * 3. Environment variables include FEATURE_FLAG_SHOW_ to make it clear that it is a boolean flag.
 * 2. Exported variables are prefixed with featureFlag to make it clear that it is a feature flag.
 * 4. Flags are set to 'on' or 'off'
 */

export const featureFlagShowGoogleSignIn =
  process.env.NEXT_PUBLIC_FEATURE_FLAG_SHOW_GOOGLE_SIGN_IN === 'on'

export const featureFlagEnableMapLocationSharing =
  process.env.NEXT_PUBLIC_FEATURE_FLAG_ENABLE_MAP_LOCATION_URL_PARAMS === 'on'

export const featureFlagEnableGoogleRecaptcha =
  process.env.NEXT_PUBLIC_FEATURE_FLAG_ENABLE_GOOGLE_RECAPTCHA === 'on'
