import { useCallback } from 'react'
import { Modal } from '@electro/shared-ui-components'
import { useMapModal } from '@electro/consumersite/src/components/Map/hooks'

import {
  RoutePlannerModalScreen,
  RoutePlannerErrorModalScreen,
} from '@electro/consumersite/src/components/Map/components/NetworkMapModal/components'

import {
  ModalScreenNames,
  MapModalScreenName,
} from '@electro/consumersite/src/components/Map/types'

const { ROUTE_PLAN_SCREEN, ROUTE_PLAN_ERROR_SCREEN } = ModalScreenNames

export const NetworkMapModal = () => {
  const [{ activeModalScreen }, { closeModal, isActiveModalScreen }] = useMapModal()

  const mapScreenToComponent = useCallback((screen: MapModalScreenName) => {
    const components = {
      [ROUTE_PLAN_SCREEN]: <RoutePlannerModalScreen />,
      [ROUTE_PLAN_ERROR_SCREEN]: <RoutePlannerErrorModalScreen />,
    }

    return components[screen]
  }, [])

  return (
    <Modal open={!isActiveModalScreen(null)} onClose={closeModal} size="md">
      {mapScreenToComponent(activeModalScreen)}
    </Modal>
  )
}
