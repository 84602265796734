import { MarkerNamesEnum } from '@electro/consumersite/src/components/Map/helpers/getMarkerIcon'

import { MapFiltersOperatorType } from '@electro/consumersite/src/components/Map/types'
import { Speeds } from '@electro/consumersite/generated/graphql'
import {
  SymbolLayerSpecification,
  CircleLayerSpecification,
  HeatmapLayerSpecification,
  ExpressionSpecification,
} from 'mapbox-gl'

const {
  EJN_SLOW,
  EJN_SLOW_AVAILABLE,
  EJN_SLOW_OCCUPIED,
  EJN_SLOW_OUT_OF_ORDER,
  EJN_FAST,
  EJN_FAST_AVAILABLE,
  EJN_FAST_OCCUPIED,
  EJN_FAST_OUT_OF_ORDER,
  EJN_RAPID,
  EJN_RAPID_AVAILABLE,
  EJN_RAPID_OCCUPIED,
  EJN_RAPID_OUT_OF_ORDER,
  EJN_ULTRA_RAPID,
  EJN_ULTRA_RAPID_AVAILABLE,
  EJN_ULTRA_RAPID_OCCUPIED,
  EJN_ULTRA_RAPID_OUT_OF_ORDER,
} = MarkerNamesEnum

interface LayerStyleArgs {
  minzoom?: number
  maxzoom?: number
}

export const ejLocationLayerStyle = ({
  minzoom = 4.0,
  maxzoom = 0,
}: LayerStyleArgs = {}): SymbolLayerSpecification => ({
  minzoom,
  maxzoom,
  slot: 'top',
  id: 'charging_locations_ejn_symbols',
  source: 'charging_locations',
  'source-layer': 'hits',
  type: 'symbol',
  // @ts-expect-error ts(2322)
  filter: ['==', ['get', 'is_ejn_location'], ['boolean', true]],
  layout: {
    'icon-anchor': 'center',
    'icon-size': ['interpolate', ['linear'], ['zoom'], 4.0, 0.1, 5.0, 0.5, 22.0, 1],
    'icon-image': [
      'case',
      [
        'all',
        ['==', ['get', 'is_ejn_location'], ['boolean', true]],
        ['==', ['get', 'max_speed'], Speeds.Slow],
        ['==', ['get', 'current_status'], 'AVAILABLE'],
      ],
      EJN_SLOW_AVAILABLE,

      [
        'all',
        ['==', ['get', 'is_ejn_location'], ['boolean', true]],
        ['==', ['get', 'max_speed'], Speeds.Slow],
        ['==', ['get', 'current_status'], 'CHARGING'],
      ],
      EJN_SLOW_OCCUPIED,

      [
        'all',
        ['==', ['get', 'is_ejn_location'], ['boolean', true]],
        ['==', ['get', 'max_speed'], Speeds.Slow],
        ['==', ['get', 'current_status'], 'OUT_OF_ORDER'],
      ],
      EJN_SLOW_OUT_OF_ORDER,

      [
        'all',
        ['==', ['get', 'is_ejn_location'], ['boolean', true]],
        ['==', ['get', 'max_speed'], Speeds.Slow],
        ['==', ['get', 'current_status'], 'NO_DATA'],
      ],
      EJN_SLOW,

      [
        'all',
        ['==', ['get', 'is_ejn_location'], ['boolean', true]],
        ['==', ['get', 'max_speed'], Speeds.Slow],
      ],
      EJN_SLOW,

      [
        'all',
        ['==', ['get', 'is_ejn_location'], ['boolean', true]],
        ['==', ['get', 'max_speed'], Speeds.Fast],
        ['==', ['get', 'current_status'], 'AVAILABLE'],
      ],
      EJN_FAST_AVAILABLE,

      [
        'all',
        ['==', ['get', 'is_ejn_location'], ['boolean', true]],
        ['==', ['get', 'max_speed'], Speeds.Fast],
        ['==', ['get', 'current_status'], 'CHARGING'],
      ],
      EJN_FAST_OCCUPIED,

      [
        'all',
        ['==', ['get', 'is_ejn_location'], ['boolean', true]],
        ['==', ['get', 'max_speed'], Speeds.Fast],
        ['==', ['get', 'current_status'], 'OUT_OF_ORDER'],
      ],
      EJN_FAST_OUT_OF_ORDER,

      [
        'all',
        ['==', ['get', 'is_ejn_location'], ['boolean', true]],
        ['==', ['get', 'max_speed'], Speeds.Fast],
        ['==', ['get', 'current_status'], 'NO_DATA'],
      ],
      EJN_FAST,

      [
        'all',
        ['==', ['get', 'is_ejn_location'], ['boolean', true]],
        ['==', ['get', 'max_speed'], Speeds.Fast],
      ],
      EJN_FAST,

      [
        'all',
        ['==', ['get', 'is_ejn_location'], ['boolean', true]],
        ['==', ['get', 'max_speed'], Speeds.Rapid],
        ['==', ['get', 'current_status'], 'AVAILABLE'],
      ],
      EJN_RAPID_AVAILABLE,

      [
        'all',
        ['==', ['get', 'is_ejn_location'], ['boolean', true]],
        ['==', ['get', 'max_speed'], Speeds.Rapid],
        ['==', ['get', 'current_status'], 'CHARGING'],
      ],
      EJN_RAPID_OCCUPIED,

      [
        'all',
        ['==', ['get', 'is_ejn_location'], ['boolean', true]],
        ['==', ['get', 'max_speed'], Speeds.Rapid],
        ['==', ['get', 'current_status'], 'OUT_OF_ORDER'],
      ],
      EJN_RAPID_OUT_OF_ORDER,

      [
        'all',
        ['==', ['get', 'is_ejn_location'], ['boolean', true]],
        ['==', ['get', 'max_speed'], Speeds.Rapid],
        ['==', ['get', 'current_status'], 'NO_DATA'],
      ],
      EJN_RAPID,

      [
        'all',
        ['==', ['get', 'is_ejn_location'], ['boolean', true]],
        ['==', ['get', 'max_speed'], Speeds.Rapid],
      ],
      EJN_RAPID,

      [
        'all',
        ['==', ['get', 'is_ejn_location'], ['boolean', true]],
        ['==', ['get', 'max_speed'], Speeds.Ultra],
        ['==', ['get', 'current_status'], 'AVAILABLE'],
      ],
      EJN_ULTRA_RAPID_AVAILABLE,

      [
        'all',
        ['==', ['get', 'is_ejn_location'], ['boolean', true]],
        ['==', ['get', 'max_speed'], Speeds.Ultra],
        ['==', ['get', 'current_status'], 'CHARGING'],
      ],
      EJN_ULTRA_RAPID_OCCUPIED,

      [
        'all',
        ['==', ['get', 'is_ejn_location'], ['boolean', true]],
        ['==', ['get', 'max_speed'], Speeds.Ultra],
        ['==', ['get', 'current_status'], 'OUT_OF_ORDER'],
      ],
      EJN_ULTRA_RAPID_OUT_OF_ORDER,

      [
        'all',
        ['==', ['get', 'is_ejn_location'], ['boolean', true]],
        ['==', ['get', 'max_speed'], Speeds.Ultra],
        ['==', ['get', 'current_status'], 'NO_DATA'],
      ],
      EJN_ULTRA_RAPID,

      [
        'all',
        ['==', ['get', 'is_ejn_location'], ['boolean', true]],
        ['==', ['get', 'max_speed'], Speeds.Ultra],
      ],
      EJN_ULTRA_RAPID,

      EJN_SLOW,
    ],
    'icon-allow-overlap': true,
  },
})

export const nonEjLocationCircleLayerStyle = ({
  minzoom = 4.0,
  maxzoom = 0,
}: LayerStyleArgs = {}): CircleLayerSpecification => ({
  minzoom,
  maxzoom,
  slot: 'top',
  // @ts-expect-error ts(2322)
  filter: ['==', ['get', 'is_ejn_location'], ['boolean', false]],
  paint: {
    'circle-stroke-width': [
      'interpolate',
      ['linear'],
      ['zoom'],
      0.0,
      0.2,
      3.5,
      1.0,
      4.0,
      0.0,
      5.0,
      4.5,
      22.0,
      7.0,
    ],
    'circle-color': [
      'case',
      ['==', ['get', 'max_speed'], Speeds.Slow],
      '#FFFFFF',
      ['==', ['get', 'max_speed'], Speeds.Fast],
      '#FFFFFF',
      ['==', ['get', 'max_speed'], Speeds.Rapid],
      '#FFC532',
      ['==', ['get', 'max_speed'], Speeds.Ultra],
      '#FF0074',
      '#FFFFFF',
    ],
    'circle-stroke-color': '#271C37',
    'circle-radius': ['interpolate', ['linear'], ['zoom'], 4.0, 1.5, 7.0, 3.0, 22.0, 4.0],
  },
  id: 'charging_locations_non_ejn_circles',
  source: 'charging_locations',
  'source-layer': 'hits',
  type: 'circle',
})

export const allLocationsLayerStyle = ({
  minzoom = 0,
  maxzoom = 4.0,
}: LayerStyleArgs = {}): CircleLayerSpecification => ({
  maxzoom,
  minzoom,
  paint: {
    'circle-color': '#271C37',
    'circle-radius': 1.5,
  },
  id: 'charging_locations_all',
  source: 'charging_locations',
  'source-layer': 'hits',
  type: 'circle',
  slot: 'top',
})

export const presentationScreenAllLocationsLayerStyle = ({
  minzoom = 0,
  maxzoom = 7.0,
}: LayerStyleArgs = {}): CircleLayerSpecification => ({
  maxzoom,
  minzoom,
  paint: {
    'circle-color': [
      'case',
      ['==', ['get', 'is_ejn_location'], ['boolean', false]],
      '#FFFFFF',
      ['==', ['get', 'is_ejn_location'], ['boolean', true]],
      '#32CD32',
      '#FFFFFF',
    ],
    'circle-stroke-width': [
      'interpolate',
      ['linear'],
      ['zoom'],
      0.0,
      1.0,
      4.0,
      1.0,
      5.0,
      4.5,
      22.0,
      7.0,
    ],
    'circle-stroke-color': '#271C37',
    'circle-radius': ['interpolate', ['linear'], ['zoom'], 4.0, 1.5, 7.0, 3.0, 22.0, 4.0],
  },
  id: 'charging_locations_all',
  source: 'charging_locations',
  'source-layer': 'hits',
  type: 'circle',
  slot: 'top',
})

export const heatmapLayerStyle: HeatmapLayerSpecification = {
  id: 'heatmap',
  type: 'heatmap',
  source: 'charging_locations',
  'source-layer': 'hits',
  slot: 'top',
  paint: {
    'heatmap-weight': 0.6,
    // Increase the heatmap color weight weight by zoom level
    // heatmap-intensity is a multiplier on top of heatmap-weight
    'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, 22, 2],
    // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
    // Begin color ramp at 0-stop with a 0-transparancy color
    // to create a blur-like effect.
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0,
      'rgba(0, 0, 255, 0)',
      0.1,
      'royalblue',
      0.3,
      'cyan',
      0.5,
      'lime',
      0.7,
      'yellow',
      1,
      'red',
    ],
    'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 5, 22, 30],
    'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 0, 0.7, 17, 1],
  },
}

/**
 * @param operators
 * @returns the operator object with a unique colour property
 */
export const bindOperatorToKeyColour = async (operators: MapFiltersOperatorType[]) => {
  const { operatorsKeyColours } = await import('@electro/consumersite/src/components/Map/constants')

  return operators.map((operator, index) => ({ ...operator, colour: operatorsKeyColours[index] }))
}

/**
 *
 * @param operators
 * @returns an object containing the bgCases and borderCases for the operator layer
 * @description
 * This function generates the bgCases and borderCases for the operator layer
 * It uses the unique operator colour to generate the bgCases. All the borderCases are set to #180048
 */
const generateCircleBgExpressionByOperators = async (operators: MapFiltersOperatorType[]) => {
  const operatorsWithColour = await bindOperatorToKeyColour(operators)

  const bgCases = []
  const borderCases = []

  operatorsWithColour.forEach(({ pk, colour }) => {
    borderCases.push(['==', ['get', 'display_operator'], pk])
    borderCases.push('#180048')

    bgCases.push(['==', ['get', 'display_operator'], pk])
    bgCases.push(colour)
  })

  return { bgCases, borderCases }
}

/**
 *
 * @param operators
 * @returns the Mapbox layer styles for the operator layer
 * @description
 * This function generates the style for the operator layer
 * It uses a list of operators to generate a color pair [border, bg-color] for each operator
 * It then uses the color pair to generate the style for the operator layer.
 * These color pairs are predictable and will match the colors seen in the operator
 * controls component
 * @see bindOperatorToKeyColour
 */
export const operatorsLayerStyle = async (
  operators: MapFiltersOperatorType[],
): Promise<CircleLayerSpecification> => {
  const { bgCases, borderCases } = await generateCircleBgExpressionByOperators(operators)

  const circleBgColor: string | ExpressionSpecification = ['case', ...bgCases, 'rgba(0,0,0,0)']
  const circleBorderColor: string | ExpressionSpecification = [
    'case',
    ...borderCases,
    'rgba(0,0,0,0)',
  ]

  return {
    paint: {
      'circle-color': circleBgColor,
      'circle-radius': ['interpolate', ['linear'], ['zoom'], 0.0, 1, 10.0, 5.0],
      'circle-stroke-width': ['interpolate', ['linear'], ['zoom'], 0.0, 0.5, 5.0, 1],
      'circle-stroke-color': circleBorderColor,
    },
    id: 'operator_locations',
    source: 'operator_locations',
    'source-layer': 'hits',
    type: 'circle',
    slot: 'top',
  }
}
