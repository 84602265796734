import { tw } from '@electro/shared/utils/tailwind-merge'
import Image from 'next/image'

import { Button, Typography } from '@electro/shared-ui-components'
import { useMapModal, useMarkers, useRoute } from '@electro/consumersite/src/components/Map/hooks'
import { SelectRouteOption } from '@electro/consumersite/src/components/Map/components/RoutePlanner/components'

import {
  RouteDetailStagesEnum,
  ModalScreenNames,
} from '@electro/consumersite/src/components/Map/types'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

const styles = {
  panel: {
    root: tw(
      'shadow-md fixed bottom-0 transition-all overflow-hidden top-14',
      'px-8 pt-4 w-full h-full',
      'sm:rounded-3xl sm:px-9 sm:max-w-sm sm:left-2 sm:right-2 sm:top-20 sm:h-min',
      'sm:max-h-[calc(100vh-theme(spacing.24))] sm:overflow-auto sm:rounded-t-3xl',
      'bg-base/80 filter backdrop-blur',
    ),
  },
  actionButtons: 'flex overflow-x-auto justify-between gap-2 py-4 mt-8',
  routeSummary: {
    root: tw(
      'mt-2 mb-4 pr-4',
      'flex flex-col items-start group relative',
      'before:content-[""]',
      'before:absolute',
      'before:left-0',
      'before:ml-2',
      'before:h-full',
      'before:w-0',
      'before:top-0',
      'before:border-l-4',
      'before:border-dotted',
      'before:border-white',
    ),
    item: 'pl-8 pt-2 pb-2 relative first:pt-0 last:pb-0',
    itemList: 'border-t border-t-tertiary-shade',
    icon: 'absolute -left-0.5',
  },
}

export const SelectRoute = () => {
  const router = useRouter()
  const { t } = useTranslation('common')
  const [
    { routes, activeRouteId },
    { selectActiveRoute, setRouteDetailsPanelScreen, clearRoutes },
  ] = useRoute()
  const [, { setActiveModalScreen }] = useMapModal()
  const [, { clearActiveMarker, toggleLocationDetailsPanel }] = useMarkers()

  const handleExpandRoute = (routeId) => () => {
    toggleLocationDetailsPanel({ open: false })
    clearActiveMarker()
    selectActiveRoute(routeId)
  }

  function handleEditRouteClick() {
    setActiveModalScreen(ModalScreenNames.ROUTE_PLAN_SCREEN)()
  }

  function handleClear() {
    clearRoutes()
  }

  function handleSelectRoute() {
    setRouteDetailsPanelScreen(RouteDetailStagesEnum.VIEWING_ROUTE_STEPS)
  }

  return routes ? (
    <div className={styles.panel.root} data-testid="select-route-panel">
      <div className={styles.routeSummary.root}>
        <div className={styles.routeSummary.item}>
          <span className={styles.routeSummary.icon}>
            <Image alt="" width={24} height={28} src="/images/markers/route-destination.png" />
          </span>
          <Typography variant="h4">{routes.startPoint}</Typography>
        </div>
        <div className={styles.routeSummary.item}>
          <span className={styles.routeSummary.icon}>
            <Image alt="" width={24} height={28} src="/images/markers/route-waypoint.png" />
          </span>
          <Typography variant="h4">{routes.finalDestination}</Typography>
        </div>
      </div>
      <div className={styles.routeSummary.itemList}>
        {Object.keys(routes.data).map((routeId) => (
          <SelectRouteOption
            key={routeId}
            routes={routes}
            onSelect={handleSelectRoute}
            onExpand={handleExpandRoute(routeId)}
            expanded={activeRouteId === routeId}
            route={routes.data[routeId]}
          />
        ))}
      </div>
      <div className={styles.actionButtons}>
        {/* eslint-disable-next-line no-constant-condition */}
        {!router.asPath?.includes('v2') && false ? (
          <Button fullWidth size="xs" variant="outline" onClick={handleEditRouteClick}>
            {t('map.route_planner.button.edit_route')}
          </Button>
        ) : null}
        <Button fullWidth size="xs" variant="outline" onClick={handleClear}>
          {t('map.route_planner.button.clear')}
        </Button>
      </div>
    </div>
  ) : null
}
