import { Route } from '@electro/consumersite/src/components/Map/types'
import durationFromSeconds from '@electro/shared/utils/durationFromSeconds'

interface Duration {
  totalSeconds: number
  hours: number
  minutes: number
}

/**
 * @param route - Route object
 * @returns The complete journey duration (charging + driving) in {hours, minutes, totalSeconds}
 */
export default function getTotalRouteDuration(route: Route): Duration {
  if (!route) {
    return {
      totalSeconds: null,
      hours: null,
      minutes: null,
    }
  }
  const totalElapsedJourneytime =
    route.summary.totalDriveDurationSeconds + route.summary.totalChargeDurationSeconds
  const duration = durationFromSeconds(totalElapsedJourneytime)
  return {
    totalSeconds: totalElapsedJourneytime,
    hours: duration.hours + duration.days * 24,
    minutes: duration.minutes,
  }
}
