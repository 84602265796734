import { useMount } from 'react-use'
import { useEffect, useState } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import useTranslation from 'next-translate/useTranslation'
import { useMapFilters } from '@electro/consumersite/src/components/Map/hooks'
import { MapFiltersOperatorType } from '@electro/consumersite/src/components/Map/types'
import { getScrollbarVisible } from '@electro/consumersite/src/utils/getScrollbarVisible'
import { OperatorListTableItem } from '@electro/consumersite/src/components/Map/components/MapSidebar/panels/OperatorList/components'
import { Button, ScrollShadow } from '@electro/shared-ui-components'
import { XCircleIcon } from '@heroicons/react/16/solid'
// eslint-disable-next-line import/no-unresolved
import { mapFiltersOptions } from '@electro/consumersite/generated/mapBuildData'

const styles = {
  root: 'flex overflow-y-hidden -mb-[38px] p-2 bg-base rounded-t-lg',
  list: {
    root: 'w-full flex flex-col overflow-y-scroll [@supports_not_(scrollbar-width:thin)]:pr-0.5',
    table: 'flex flex-col gap-y-1',
    noScrollbar: 'pr-2.5 [@supports_not_(scrollbar-width:thin)]:pr-3',
    headers: 'mt-2 mb-1 ml-2 border-b border-secondary',
    selectedOperatorsHeader: {
      root: 'relative',
      clearButton: {
        root: 'absolute top-2 right-1',
        icon: 'w-3 h-3 mr-1',
      },
    },
    noOperatorsSelectedItem: '[&_span]:min-w-0 [&_p]:text-gray-400 [&_p]:-ml-2 cursor-default',
  },
}

interface OperatorListTableProps {
  searchQuery: string
  showPartneredOnly: boolean
}

/** Independently filterable list of operators, for use in the map filters */
export const OperatorListTable = ({ searchQuery, showPartneredOnly }: OperatorListTableProps) => {
  const { t } = useTranslation('common')
  const [{ currentFilters }, { updateFilters }] = useMapFilters()

  const [scrollbarVisible, setScrollbarVisible] = useState<boolean>(false)

  const [selectedList, setSelectedList] = useState<MapFiltersOperatorType[]>([])
  const [operatorList, setOperatorList] = useState<MapFiltersOperatorType[]>([])

  /** Adds the operator to the current filters, and toggles partnered with electroverse off for non-EJN operators */
  const addOperatorToFilters = (operator: MapFiltersOperatorType) => {
    if (!operator.isEjn) updateFilters({ electroverseOnly: false })
    updateFilters({ operators: [...currentFilters.operators, operator] })
  }

  /** Removes the operator from the current filters */
  const removeOperatorFromFilters = (operator: MapFiltersOperatorType) => {
    const updatedOperators = currentFilters.operators.filter(({ pk }) => pk !== operator.pk)
    updateFilters({ operators: updatedOperators })
  }

  /** Filters the lists of selected and unselected operators using the searchbox and partnered toggle */
  useEffect(() => {
    const filteredList: MapFiltersOperatorType[] = mapFiltersOptions.operators
      .filter(({ name }) => (searchQuery ? name.toLowerCase().includes(searchQuery) : true))
      .filter(({ isEjn }) => (showPartneredOnly ? isEjn : true))

    const selected = filteredList.filter((operator) =>
      currentFilters.operators.some(({ pk }) => pk === operator.pk),
    )
    const unselected = filteredList.filter(
      (operator) => !currentFilters.operators.some(({ pk }) => pk === operator.pk),
    )

    /* Object.groupBy requires node v21 for jest testing. We'll switch back when we upgrade or move to Playwright. */
    // const { selected, unselected } = Object.groupBy(filteredList, (operator) =>
    //   currentFilters.operators.some(({ pk }) => pk === operator.pk) ? 'selected' : 'unselected',
    // )

    setSelectedList(selected ?? [])
    setOperatorList(unselected ?? [])
  }, [currentFilters.operators, searchQuery, showPartneredOnly])

  /** Adjust padding on panel based on the scrollbar visibility */
  useMount(() => setScrollbarVisible(getScrollbarVisible()))

  return (
    <ScrollShadow className={styles.root}>
      <div
        className={tw({
          [styles.list.root]: true,
          [styles.list.noScrollbar]: !scrollbarVisible,
        })}
      >
        <span className={styles.list.selectedOperatorsHeader.root}>
          <h4 className={styles.list.headers}>{t('map.operator_list.selected_operators')}</h4>

          <Button
            size="2xs"
            disabled={selectedList.length === 0}
            onClick={() => updateFilters({ operators: [] })}
            className={styles.list.selectedOperatorsHeader.clearButton.root}
          >
            <XCircleIcon className={styles.list.selectedOperatorsHeader.clearButton.icon} />
            {t('map.common.buttons.clear')}
          </Button>
        </span>

        {selectedList.length === 0 && (
          <OperatorListTableItem
            key="no-operators-selected"
            operator={{
              pk: 99999999999,
              name: t('map.operator_list.no_operators_selected'),
              isEjn: false,
            }}
            className={styles.list.noOperatorsSelectedItem}
          />
        )}

        <div className={styles.list.table} data-testid="operator-list-selected-table">
          {selectedList.map((operator) => (
            <OperatorListTableItem
              isSelected
              key={operator.pk}
              operator={operator}
              onClick={() => removeOperatorFromFilters(operator)}
            />
          ))}
        </div>

        <h4 className={styles.list.headers}>{t('map.operator_list.operators')}</h4>

        <div className={styles.list.table} data-testid="operator-list-unselected-table">
          {operatorList.map((operator) => (
            <OperatorListTableItem
              key={operator.pk}
              operator={operator}
              onClick={() => addOperatorToFilters(operator)}
            />
          ))}
        </div>
      </div>
    </ScrollShadow>
  )
}
