import { useState, useCallback, useMemo, useEffect } from 'react'
import { ApolloError } from '@apollo/client'

import { useErrorNotificationEffect } from '@electro/shared/hooks'
import { VehicleRegLookupFields } from '@electro/shared/components'
import { useAddUserVehicle, useFetchVehiclesByReg } from '@electro/consumersite/src/services'

import { VehicleType, Mutation } from '@electro/consumersite/generated/graphql'
import USER_VEHICLES_QUERY from '@electro/consumersite/src/graphql/userVehiclesQuery.graphql'

export enum AddAnEvFormStagesEnum {
  ENTER_REGISTRATION = 'ENTER_REGISTRATION',
  SELECT_VEHICLE = 'SELECT_VEHICLE',
  ADD_BY_MAKE_AND_MODEL = 'ADD_BY_MAKE_AND_MODEL',
  VEHICLE_ADDED = 'VEHICLE_ADDED',
  NOT_AN_EV = 'NOT_AN_EV',
}

export type AddAnEvFormStageNames = `${AddAnEvFormStagesEnum}`

const { ENTER_REGISTRATION, SELECT_VEHICLE, ADD_BY_MAKE_AND_MODEL, VEHICLE_ADDED } =
  AddAnEvFormStagesEnum

export interface IUseAddAnEv {
  toggleManualVehicleSearchEnabled: () => void
  vehicle: VehicleType
  setVehicle: (vehicle: VehicleType) => void
  resetVehicle: () => void
  isElectric: boolean
  vehicleOptions: VehicleType[]
  addVehicleData: Pick<Mutation, 'addUserVehicle'>
  addVehicleLoading: boolean
  vehicleAdded: boolean
  handleAddVehicle: () => void
  handleLookupSubmit: ({ regNumber }: VehicleRegLookupFields) => void
  handleSelectVehicleOption: (nextVehicle: VehicleType) => void
  lookupError: ApolloError
  lookupLoading: boolean
  lookupCalled: boolean
  isFormStage: (stage: AddAnEvFormStageNames) => boolean
  formStage: AddAnEvFormStageNames
  setFormStage: (stage: AddAnEvFormStageNames) => void
  regNumber: string
}

export function useAddAnEv(): IUseAddAnEv {
  const [formStage, setFormStage] = useState<AddAnEvFormStageNames>(ADD_BY_MAKE_AND_MODEL)
  const [vehicleAdded, setVehicleAdded] = useState<boolean>(false)
  const [vehicle, setVehicle] = useState<VehicleType>(null)
  const [regNumber, setRegNumber] = useState<string>('')
  const [isElectric, setIsElectric] = useState<boolean>(true)
  const [vehicleOptions, setVehicleOptions] = useState<VehicleType[]>(null)

  const [
    addVehicleMutation,
    { loading: addVehicleLoading, error: addVehicleError, data: addVehicleData },
  ] = useAddUserVehicle({
    notifyOnNetworkStatusChange: true,
    refetchQueries: [{ query: USER_VEHICLES_QUERY }],
  })

  const [
    getVehicleByRegNumber,
    { error: lookupError, loading: lookupLoading, called: lookupCalled },
  ] = useFetchVehiclesByReg({ notifyOnNetworkStatusChange: true })

  const { errorNotification } = useErrorNotificationEffect({
    error: addVehicleError || lookupError,
    message: 'Could not add this vehicle! Try again later.',
  })

  const isFormStage = (stage: AddAnEvFormStageNames) => stage === formStage

  const resetVehicle = useMemo(() => () => setVehicle(null), [])

  const toggleManualVehicleSearchEnabled = () => {
    resetVehicle()
    setFormStage(formStage === ADD_BY_MAKE_AND_MODEL ? ENTER_REGISTRATION : ADD_BY_MAKE_AND_MODEL)
  }

  const vehicleName = useMemo(
    () =>
      vehicle
        ? `${vehicle.vehicleMake} ${vehicle.vehicleModel} ${
            vehicle.vehicleModelVersion ? vehicle.vehicleModelVersion : ''
          }`
        : '',
    [vehicle],
  )

  const handleAddVehicle = async () => {
    try {
      await addVehicleMutation({
        variables: {
          octoEvDbId: vehicle.octoEvDbId,
          regNumber,
          name: `${vehicleName} (${vehicle.batteryCapacityFull}kWh)`,
        },
      })
      setFormStage(VEHICLE_ADDED)
    } catch (err) {
      errorNotification()
    }
  }

  const handleLookupSubmit = useCallback(
    async ({ regNumber: lookupRegNumber }: VehicleRegLookupFields) => {
      resetVehicle()
      /**
       * If there’s no exact matches for the vehicle model by reg number then
       * the supplying useHeuristics arg returns the closest models.
       * So, as long as the reg lookup returns a known make, there should
       * be options returned.
       */
      try {
        const response = await getVehicleByRegNumber({
          variables: { regNumber: lookupRegNumber, useHeuristics: true },
        })
        setIsElectric(response.data.extendedVehicleLookup.isElectric)
        setVehicleOptions(response.data.extendedVehicleLookup.options)
        if (response.data.extendedVehicleLookup.isElectric) {
          setFormStage(SELECT_VEHICLE)
          setRegNumber(lookupRegNumber)
        }
      } catch (err) {
        errorNotification()
      }
    },
    [errorNotification, getVehicleByRegNumber, resetVehicle],
  )

  const handleSelectVehicleOption = useCallback((nextVehicle: VehicleType) => {
    setVehicle(nextVehicle)
  }, [])

  // Check for changes to the add vehicle mutation data. If it's successful
  // set a flag to state. This removes the need for any async behaviour in the
  // add vehicle handler.
  useEffect(() => {
    if (addVehicleData?.addUserVehicle.success) setVehicleAdded(true)
    return () => setVehicleAdded(false)
  }, [addVehicleData])

  return {
    regNumber,
    isFormStage,
    formStage,
    setFormStage,
    toggleManualVehicleSearchEnabled,
    vehicle,
    setVehicle,
    resetVehicle,
    isElectric,
    vehicleOptions,
    addVehicleData,
    addVehicleLoading,
    vehicleAdded,
    handleAddVehicle,
    handleLookupSubmit,
    handleSelectVehicleOption,
    lookupError,
    lookupLoading,
    lookupCalled,
  }
}
