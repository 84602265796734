import Image from 'next/image'
import { Modal } from '@electro/shared-ui-components'
import { useRoute } from '@electro/consumersite/src/components/Map/hooks'
import { RouteStatusEnum } from '@electro/consumersite/src/components/Map/types'

export const RoutePlannerErrorModalScreen = () => {
  const [{ error }] = useRoute()

  return (
    <>
      <Modal.CloseButton />
      <Modal.Body>
        <div className="text-center">
          <div className="flex items-center justify-center">
            <Image
              src="/images/mr-zap-oh-no.png"
              alt="Mr zap looking sad because he dropped an ice cream"
              width="317"
              height="321"
            />
          </div>
          <h2>Oh No!</h2>
          {error ? (
            <>
              {error === RouteStatusEnum.NOTFOUND || error === RouteStatusEnum.INVALID ? (
                <>
                  <p>
                    We couldn&apos;t find a charging route for these options. It may be the case
                    that there aren&apos;t enough fast chargers on or near your desired route.
                    However, to help plan a route try:
                  </p>
                  <ul>
                    <li>A higher starting charge </li>
                    <li>A lower destination charge </li>
                    <li>Planning a longer route in shorter steps</li>
                    <li>
                      Turning off <strong>&quot;Prefer Electroverse chargers&quot;</strong>
                    </li>
                  </ul>
                </>
              ) : null}
              {error === RouteStatusEnum.ADDRESS_NOT_FOUND
                ? "We couldn't find one or more of the addresses you entered."
                : null}
              {error === RouteStatusEnum.ADDRESS_DIFFERENT_REGIONS
                ? 'We cannot plot a route across different global regions!'
                : null}
              {error === RouteStatusEnum.ERROR ? 'Something went wrong!' : null}
            </>
          ) : (
            "We couldn't plan your route!"
          )}
        </div>
      </Modal.Body>
    </>
  )
}
