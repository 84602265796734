import useTranslation from 'next-translate/useTranslation'
import { SwitchToggle } from '@electro/shared-ui-components'
import { useRoutePlannerForm } from '@electro/consumersite/src/components/Map/hooks'

const styles = {
  root: 'flex flex-col gap-y-2 mx-3 text-sm',
  toggle: 'flex items-center justify-between',
}

export const RoutePlannerToggles = () => {
  const { t } = useTranslation('common')
  const [{ fieldValues }, { updateFormField }] = useRoutePlannerForm({})

  return (
    <div className={styles.root}>
      <div className={styles.toggle}>
        {t('map.route_planner.toggles.electroverse_only')}
        <SwitchToggle
          checked={fieldValues.electroverseOnly}
          onChange={() => updateFormField('electroverseOnly', !fieldValues.electroverseOnly)}
        />
      </div>

      <div className={styles.toggle}>
        {t('map.route_planner.toggles.avoid_tolls')}
        <SwitchToggle
          checked={fieldValues.avoidTolls}
          onChange={() => updateFormField('avoidTolls', !fieldValues.avoidTolls)}
        />
      </div>
    </div>
  )
}
