import dynamic from 'next/dynamic'
import { FC, SVGProps } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'

interface LazyIconProps extends SVGProps<SVGElement> {
  name: string
}

/** Lazily imports icons from the shared folder */
export const LazyIcon = ({ name, className, ...props }: LazyIconProps) => {
  const SVG = dynamic(() => import(`@electro/shared/icons/${name}.svg`)) as FC<SVGProps<SVGElement>>

  let defaultSize = 'w-6 h-6'
  if (name.startsWith('20/')) defaultSize = 'w-5 h-5'
  else if (name.startsWith('16/')) defaultSize = 'w-4 h-4'

  return <SVG className={tw(defaultSize, className)} {...props} />
}
