type SeasonsType = 'halloween' | 'christmas' | 'easter'

interface SeasonalDatesType {
  name: SeasonsType
  start: string
  end: string
}

// prettier-ignore
const monthsArray = [ "JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC" ]
const currentYear = new Date().getFullYear()

const seasonalDates: SeasonalDatesType[] = [
  { name: 'halloween', start: '24 OCT 2000', end: '3 NOV 2000' },
  { name: 'christmas', start: '12 DEC 2000', end: '30 DEC 2000' },
  { name: 'easter', start: getEaster(currentYear).start, end: getEaster(currentYear).end },
]

const seasonalImageMap = {
  'zap-black-hole': {
    DEFAULT: '/images/seasonal/default/zap-black-hole.svg',
    halloween: '/images/seasonal/halloween/zap-black-hole-halloween.svg',
    christmas: '/images/seasonal/christmas/zap-black-hole-christmas.svg',
    easter: '/images/seasonal/easter/zap-black-hole-easter.svg',
  },
  'where-to-next': {
    DEFAULT: '/images/seasonal/default/where-to-next.svg',
    halloween: '/images/seasonal/halloween/where-to-next-halloween.svg',
  },
  constantine: {
    DEFAULT: null,
    halloween: '/images/seasonal/halloween/constantine-the-friendly-ghost.svg',
    christmas: '/images/seasonal/christmas/constantine-hot-chocolate.svg',
    easter: '/images/seasonal/easter/constantine-in-easter-egg.svg',
  },
  'constantine-2': {
    DEFAULT: null,
    halloween: '/images/seasonal/halloween/constantine-trick-or-treat.svg',
    christmas: '/images/seasonal/christmas/constantine-wreath.svg',
    easter: '/images/seasonal/easter/constantine-easter-egg-hunt.svg',
  },
  'collection-of-items': {
    DEFAULT: null,
    halloween: '/images/seasonal/halloween/pumpkins-collection.svg',
    christmas: '/images/seasonal/christmas/presents-collection.svg',
    easter: '/images/seasonal/easter/easter-eggs-collection.svg',
  },
  'profile-hat': {
    DEFAULT: null,
    halloween: '/images/seasonal/halloween/profile-witch-hat.svg',
    christmas: '/images/seasonal/christmas/profile-santa-hat.svg',
    easter: '/images/seasonal/easter/profile-bunny-ears-hat.svg',
  },
}

export const getActiveHolidaySeason = () => {
  const currentDate = new Date(new Date().setFullYear(2000))

  const activeSeason = seasonalDates.find(
    (season: SeasonalDatesType) =>
      currentDate > new Date(season.start) && currentDate < new Date(season.end),
  )

  return activeSeason ? activeSeason.name : null
}

export const getSeasonalImagePath = (imagePath: string, season: SeasonsType | null) =>
  seasonalImageMap[imagePath][season || 'DEFAULT']

/** Calculates Easter in the Gregorian/Western (Catholic and Protestant) calendar
 *  based on the algorithm by Oudin (1940) from http://www.tondering.dk/claus/cal/easter.php */
function getEaster(year: number) {
  const f = Math.floor

  const E = year % 19 // Golden Number - 1
  const A = f(year / 100)
  const S = (A - f(A / 4) - f((8 * A + 13) / 25) + 19 * E + 15) % 30 // related to Epact
  const T = S - f(S / 28) * (1 - f(29 / (S + 1)) * f((21 - E) / 11)) // number of days from 21 March to the Paschal full moon
  const e = (year + f(year / 4) + T + 2 - A + f(A / 4)) % 7 // weekday for the Paschal full moon
  const R = T - e // number of days from 21 March to the Sunday on or before the Paschal full moon

  const month = 3 + f((R + 40) / 44)
  const day = R + 28 - 31 * f(month / 4)

  const easterDate = `${day} ${monthsArray[month - 1]} ${currentYear}`

  const easterStartDate = new Date(new Date(easterDate).setDate(new Date(easterDate).getDate() - 7))
  const easterStart = `${easterStartDate.getDate()} ${monthsArray[easterStartDate.getMonth()]} 2000`

  const easterEndDate = new Date(new Date(easterDate).setDate(new Date(easterDate).getDate() + 1))
  const easterEnd = `${easterEndDate.getDate()} ${monthsArray[easterEndDate.getMonth()]} 2000`

  return { start: easterStart, end: easterEnd }
}
