import { FeatureCollection, LineString } from 'geojson'

import { EjnMarker } from '@electro/consumersite/src/components/Map/types'
import { Step, UserVehicleType } from '@electro/consumersite/generated/graphql'

export enum RouteStatusEnum {
  OK = 'ok',
  INVALID = 'invalid',
  NOTFOUND = 'notfound',
  ADDRESS_NOT_FOUND = 'address_not_found',
  ADDRESS_DIFFERENT_REGIONS = 'address_different_regions',
  ERROR = 'error',
}
export type RouteStatus = `${RouteStatusEnum}`

export enum RouteDetailStagesEnum {
  SELECTING_ROUTE = 'SELECTING_ROUTE',
  VIEWING_ROUTE_STEPS = 'VIEWING_ROUTE_STEPS',
}

export type RouteDetailStages = `${RouteDetailStagesEnum}`

export type RouteLineGeoJson = FeatureCollection<LineString>[]

export interface Route {
  id: string
  polyLine: RouteLineGeoJson
  markers: EjnMarker[]
  summary: RouteSummary
  steps: Step[]
}

export interface RoutesDictionary {
  [key: string]: Route
}

export interface RoutePathsData {
  data: RoutesDictionary
  routeCount: number
  startPoint: string
  finalDestination: string
}

export interface RouteStep {
  path: number[][]
  batteryPerc: number[]
  name: string
  color: number[]
}

export interface RouteSummary {
  status: RouteStatusEnum
  totalDistMeters: number
  totalChargeDurationSeconds: number
  totalDriveDurationSeconds: number
  stopCount: number
}

export interface RouteFormFields {
  selectedVehicle: UserVehicleType
  startingLocation: string
  waypoints: WaypointDictionary
  destination: string
  startingBatteryPerc: number
  destinationBatteryPerc: number
  ejLocationsOnly: boolean
  avoidTolls: boolean
}

export interface RouteDestinationAddress {
  address: string
}

export type WaypointDictionary = { [index: number]: string }
