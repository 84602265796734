export const RouteLineIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} fill="none" viewBox="0 0 18 19">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.328 3.983a1.317 1.317 0 1 0 0-2.633 1.317 1.317 0 0 0 0 2.633Zm0 1.35a2.667 2.667 0 1 0 0-5.333 2.667 2.667 0 0 0 0 5.333ZM3.676 14.684a1.317 1.317 0 1 0 0 2.633 1.317 1.317 0 0 0 0-2.633Zm0-1.35a2.667 2.667 0 1 0 0 5.333 2.667 2.667 0 0 0 0-5.333ZM1.341 2.83C2.247 2.04 3.511 1.666 5 1.666h4a1 1 0 1 1 0 2H5c-1.178 0-1.913.295-2.341.67C2.248 4.695 2 5.232 2 6c0 .74.246 1.29.652 1.66.413.38 1.101.672 2.157.67H11a1 1 0 1 1 0 2H5.068a8.973 8.973 0 0 1-.27 0c-1.415 0-2.628-.398-3.497-1.195C.42 8.33 0 7.212 0 6c0-1.233.42-2.362 1.341-3.17Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.659 15.837c-.905.792-2.17 1.164-3.659 1.164H9a1 1 0 1 1 0-2h4c1.178 0 1.913-.294 2.341-.669.411-.36.659-.897.659-1.664 0-.74-.246-1.29-.652-1.661-.413-.38-1.101-.671-2.157-.67H7a1 1 0 1 1 0-2h5.932c.022-.002.045-.003.068-.003.068 0 .135.001.202.003 1.415 0 2.628.398 3.497 1.195.88.807 1.301 1.924 1.301 3.136 0 1.232-.42 2.362-1.341 3.17Z"
      fill="currentColor"
    />
  </svg>
)
