export const RouteIcon = ({ className }: { className?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} fill="none" viewBox="0 0 19 17">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.63916 2.60207C4.23684 2.60207 2.22048 4.60936 2.22048 7.17152C2.22048 9.17529 3.46263 10.8539 5.15666 11.4778C5.50061 11.6045 5.83305 11.8216 6.09769 12.1366L6.63916 12.7811L7.18062 12.1366C7.44526 11.8216 7.7777 11.6045 8.12165 11.4778C9.81569 10.8539 11.0578 9.17529 11.0578 7.17152C11.0578 4.60936 9.04147 2.60207 6.63916 2.60207ZM8.69433 13.0327C11.0385 12.1694 12.7148 9.87107 12.7148 7.17152C12.7148 3.73275 9.99466 0.945068 6.63916 0.945068C3.28365 0.945068 0.563477 3.73275 0.563477 7.17152C0.563477 9.87107 2.23986 12.1694 4.58398 13.0327C4.67876 13.0676 4.76401 13.1251 4.82899 13.2025L6.21626 14.8538C6.43698 15.1165 6.84133 15.1165 7.06206 14.8538L8.44933 13.2025C8.5143 13.1251 8.59955 13.0676 8.69433 13.0327Z"
      fill="currentColor"
    />
    <path
      d="M8.29545 7.02065C8.29545 7.93579 7.55359 8.67765 6.63845 8.67765C5.72331 8.67765 4.98145 7.93579 4.98145 7.02065C4.98145 6.10551 5.72331 5.36365 6.63845 5.36365C7.55359 5.36365 8.29545 6.10551 8.29545 7.02065Z"
      fill="currentColor"
    />
    <path
      d="M18.5635 9.02418C18.5635 11.7237 16.8871 14.022 14.543 14.8854C14.4482 14.9203 14.3629 14.9778 14.298 15.0551L12.9107 16.7064C12.69 16.9692 12.2856 16.9692 12.0649 16.7064L10.6776 15.0551C10.6126 14.9778 10.5274 14.9203 10.4326 14.8854C8.08849 14.022 6.41211 11.7237 6.41211 9.02418C6.41211 5.58541 9.13228 2.79773 12.4878 2.79773C15.8433 2.79773 18.5635 5.58541 18.5635 9.02418Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4878 4.45473C10.0855 4.45473 8.06911 6.46202 8.06911 9.02418C8.06911 11.028 9.31126 12.7066 11.0053 13.3305C11.3492 13.4572 11.6817 13.6743 11.9463 13.9893L12.4878 14.6338L13.0293 13.9893C13.2939 13.6743 13.6263 13.4572 13.9703 13.3305C15.6643 12.7066 16.9065 11.028 16.9065 9.02418C16.9065 6.46202 14.8901 4.45473 12.4878 4.45473ZM14.543 14.8854C16.8871 14.022 18.5635 11.7237 18.5635 9.02418C18.5635 5.58541 15.8433 2.79773 12.4878 2.79773C9.13228 2.79773 6.41211 5.58541 6.41211 9.02418C6.41211 11.7237 8.08849 14.022 10.4326 14.8854C10.5274 14.9203 10.6126 14.9778 10.6776 15.0551L12.0649 16.7064C12.2856 16.9692 12.69 16.9692 12.9107 16.7064L14.298 15.0551C14.3629 14.9778 14.4482 14.9203 14.543 14.8854Z"
      fill="currentColor"
    />
    <circle cx="12.4871" cy="8.87319" r="1.657" fill="currentColor" />
  </svg>
)
