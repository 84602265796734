import {
  ReactNode,
  useState,
  createContext,
  useContext,
  useMemo,
  useEffect,
  CSSProperties,
  Dispatch,
} from 'react'
import { createPortal } from 'react-dom'

import { usePopper } from 'react-popper'

interface ToolTipProps {
  children: ReactNode
  disabled?: boolean
  show?: boolean
  placement?: 'top' | 'bottom' | 'left' | 'right'
}

interface ToolTipTriggerProps {
  children: string | number | ReactNode
  ariaLabel: string
  delay?: number | null
  className?: string
  onShow?: () => void
  onHide?: () => void
}

interface ToolTipBodyProps {
  children: string | number | ReactNode
  width?: number
}

interface UseTooltipContext {
  popperStyles: { [key: string]: CSSProperties }
  attributes: { [key: string]: { [key: string]: string } }
  setPopperElement: Dispatch<any>
  setReferenceElement: Dispatch<any>
  setVisible: Dispatch<boolean>
  visible: boolean
  disabled: boolean
  alwaysVisible: boolean
}

const TooltipContext = createContext<UseTooltipContext>(null)

function useTooltipContext() {
  const context = useContext(TooltipContext)
  if (!context) {
    throw new Error(
      `TooltipContext compound components cannot be rendered outside the <Tooltip/> component`,
    )
  }
  return context
}

const Trigger = ({
  children,
  ariaLabel,
  delay = null,
  className,
  onShow,
  onHide,
}: ToolTipTriggerProps) => {
  const { setReferenceElement, setVisible, alwaysVisible } = useTooltipContext()

  const [delayHandler, setDelayHandler] = useState(null)

  function handleMouseEnter(event) {
    if (alwaysVisible) return

    if (delay) {
      setDelayHandler(setTimeout(() => showTip(), delay))
    } else {
      showTip()
    }
  }

  function handleMouseLeave() {
    if (alwaysVisible) return
    if (delay) {
      clearTimeout(delayHandler)
    }
    hideTip()
  }

  function showTip() {
    setVisible(true)
    onShow?.()
  }
  function hideTip() {
    setVisible(false)
    onHide?.()
  }

  return (
    <span
      ref={setReferenceElement}
      onMouseEnter={handleMouseEnter}
      onFocus={showTip}
      onMouseLeave={handleMouseLeave}
      onBlur={hideTip}
      role="tooltip"
      aria-label={ariaLabel}
      tabIndex={-1}
      className={className}
    >
      {children}
    </span>
  )
}

const Body = ({ children, width }: ToolTipBodyProps) => {
  const { popperStyles, attributes, setPopperElement, visible, disabled } = useTooltipContext()

  return visible && !disabled
    ? createPortal(
        <div
          className="bg-base-dark/90 p-4 text-white max-w-xs rounded-xl whitespace-normal z-100 text-center"
          ref={setPopperElement}
          style={{ ...popperStyles.popper, width }}
          {...attributes.popper}
        >
          {children}
        </div>,
        document.body,
      )
    : null
}

const Tooltip = ({ children, disabled = false, show, placement = 'bottom' }: ToolTipProps) => {
  const [visible, setVisible] = useState(false)
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [{ name: 'offset', options: { offset: [0, 5] } }],
  })

  useEffect(() => {
    if (show) {
      setVisible(true)
    }
  }, [show])

  const value = useMemo(
    () => ({
      alwaysVisible: show,
      referenceElement,
      setReferenceElement,
      popperStyles,
      attributes,
      popperElement,
      setPopperElement,
      setVisible,
      visible,
      disabled,
    }),
    [show, referenceElement, popperStyles, attributes, popperElement, visible, disabled],
  )

  return <TooltipContext.Provider value={value}>{children}</TooltipContext.Provider>
}

Tooltip.Body = Body
Tooltip.Trigger = Trigger

export { Tooltip }
