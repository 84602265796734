import { Input, Slider } from '@electro/shared-ui-components'

type Props = {
  initialValue: number
  onChange: (value: number) => void
  label: string
  name: string
  max: number
  min: number
}

export const BatteryChargeSlider = ({
  initialValue,
  onChange,
  label,
  name,
  max = 100,
  min = 10,
}: Props) => (
  <Slider
    min={min}
    max={max}
    onChange={(values) => onChange(values?.[0])}
    initialValues={[initialValue]}
    name={name}
  >
    {({ values, setSliderValues }) => {
      const handleInputChange = (e) => {
        const nextValue = parseInt(e.currentTarget.value || 0, 10)
        setSliderValues([nextValue])
      }

      const handleBlur = (e) => {
        const nextValue = parseInt(e.currentTarget.value, 10)
        let sanitizedValue
        if (nextValue > max) {
          sanitizedValue = max
        } else if (nextValue <= min) {
          sanitizedValue = min
        } else {
          sanitizedValue = nextValue
        }
        if (Number.isNaN(nextValue)) {
          sanitizedValue = 0
        }
        setSliderValues([sanitizedValue])
      }

      return (
        <>
          <div className="flex items-end justify-between">
            <div className="flex-grow-1 w-full">
              <Slider.Label>{label}</Slider.Label>
            </div>
            <div className="flex-grow-0 flex items-center w-20 relative -bottom-2">
              <Input
                data-testid={name}
                fullWidth
                className="-mb-4 pb-1 pt-1 pl-0 text-center text-sm"
                name="battPercInput"
                value={values?.[0]}
                onChange={handleInputChange}
                onBlur={handleBlur}
              />
              <span className="text-lg text-base-dark/40 pb-2 pl-1">%</span>
            </div>
          </div>
          <div className="pl-1 pr-1">
            <Slider.Rail />
          </div>
        </>
      )
    }}
  </Slider>
)
