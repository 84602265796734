import { useMemo } from 'react'

import { useRoute } from '@electro/consumersite/src/components/Map/hooks'
import getTotalRouteDuration from '@electro/consumersite/src/components/Map/helpers/getTotalRouteDuration'
import durationFromSeconds from '@electro/shared/utils/durationFromSeconds'
import getMilesFromMeters from '@electro/shared/utils/getMilesFromMeters'
import { ChevronDownIcon, BoltIcon, MapPinIcon } from '@heroicons/react/24/solid'

import { RoutePlannerIcon } from '@electro/consumersite/src/icons'
import { useSwipeable } from 'react-swipeable'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { IconButton, Typography } from '@electro/shared-ui-components'
import {
  formatDistanceToLocalisedValue,
  formatDurationToLocalisedValue,
} from '@electro/shared/utils/formatters'
import { useRouter } from 'next/router'
import { Locales } from '@electro/shared/types/locales'
import useTranslation from 'next-translate/useTranslation'

const styles = {
  root: 'pb-4 px-4 relative outline-none sm:cursor-auto',
  header: {
    root: 'flex pb-2 items-center',
    dragHint: {
      button: 'w-full h-2 absolute -top-4 left-0',
      pill: 'sm:hidden w-12 h-1 rounded-full bg-white/30 absolute left-1/2 top-2 -translate-x-1/2',
    },
    mobileToggleStepButton: {
      root: 'sm:hidden',
      icon: 'w-6 h-6 flex-1 border-2 rounded-full mr-2 border-secondary text-white',
    },
    duration: 'flex-1 flex items-start justify-between',
    details: {
      root: 'grid grid-cols-2',
      text: 'inline-flex items-center mr-2',
      icon: 'h-6 w-6 mr-2 flex-shrink-0 text-white',
    },
  },
}

interface RouteDetailsPanelSummaryProps {
  /**
   * @onShowSteps
   * Callback for when steps are shown/hidden on mobile screens
   */
  onShowSteps: () => void
  /**
   * @showSteps
   * Mobile only flag to toggle the visiblility of the routes steps
   */
  showSteps: boolean
}

export const RouteDetailsPanelSummary = ({
  onShowSteps,
  showSteps,
}: RouteDetailsPanelSummaryProps) => {
  const { t } = useTranslation('common')
  const router = useRouter()
  const [{ activeRouteId, routes }] = useRoute()

  const activeRoute = routes?.data?.[activeRouteId]
  const activeRouteSummary = activeRoute?.summary

  const journeyDuration = useMemo(() => {
    const duration = getTotalRouteDuration(activeRoute)

    return {
      hours: formatDurationToLocalisedValue({
        duration: duration?.hours,
        locale: router.locale,
        unit: 'hour',
      }),
      minutes: formatDurationToLocalisedValue({
        duration: duration?.minutes,
        locale: router.locale,
        unit: 'minute',
      }),
    }
  }, [activeRoute, router.locale])

  const chargeDuration = useMemo(
    () => durationFromSeconds(activeRouteSummary?.totalChargeDurationSeconds),
    [activeRouteSummary],
  )

  const localisedTotalDistanceTraveled = useMemo((): string => {
    let distance
    if (router.locale === Locales.EN) {
      distance = Math.floor(getMilesFromMeters(activeRouteSummary?.totalDistMeters))
    } else {
      distance = Math.floor(activeRouteSummary.totalDistMeters / 1000)
    }
    return formatDistanceToLocalisedValue({
      distance,
      locale: router.locale,
      unit: router.locale === Locales.EN ? 'mile' : 'kilometer',
    })
  }, [activeRouteSummary.totalDistMeters, router.locale])

  const gestureHandlers = useSwipeable({
    onSwipedUp: () => {
      if (!showSteps) onShowSteps()
    },
    onSwipedDown: () => {
      if (showSteps) onShowSteps()
    },
  })

  const stopCount = useMemo(
    () => routes?.data?.[activeRouteId].summary.stopCount,
    [activeRouteId, routes?.data],
  )

  return (
    <div
      {...gestureHandlers}
      className={styles.root}
      data-testid="route-details-panel-summary"
      role="button"
      onClick={onShowSteps}
      onKeyDown={(e) => (e.key === 'Enter' ? onShowSteps() : null)}
      tabIndex={0}
    >
      <div className={styles.header.root} data-testid="route-duration">
        <button className={styles.header.dragHint.button} onClick={onShowSteps}>
          {!showSteps && <span data-testid="dragHint" className={styles.header.dragHint.pill} />}
          <span className="sr-only">{showSteps ? 'show route steps' : 'hide route steps'}</span>
        </button>
        {showSteps && (
          <IconButton onClick={onShowSteps} className={styles.header.mobileToggleStepButton.root}>
            <ChevronDownIcon className={styles.header.mobileToggleStepButton.icon} />
          </IconButton>
        )}
        <Typography variant="h1" className={styles.header.duration}>
          {journeyDuration?.hours} {journeyDuration?.minutes}
        </Typography>
      </div>
      <div className={styles.header.details.root}>
        <Typography variant="small" className={styles.header.details.text}>
          <RoutePlannerIcon className={styles.header.details.icon} />
          {localisedTotalDistanceTraveled}
        </Typography>
        <Typography variant="small" className={styles.header.details.text}>
          <MapPinIcon className={styles.header.details.icon} />
          {stopCount} <span className="ml-1">{t('map.route_planner.details.stop_count')}</span>
        </Typography>
        {chargeDuration && (
          <Typography variant="small" className={tw(styles.header.details.text, 'col-span-2 mt-2')}>
            <BoltIcon className={styles.header.details.icon} />
            {chargeDuration?.hours === 0 ? '' : `${chargeDuration?.hours}hr `}
            {chargeDuration?.minutes === 0 ? '' : `${chargeDuration?.minutes}mins`}
            <span className="ml-1">charge time</span>
          </Typography>
        )}
      </div>
    </div>
  )
}
