// @ts-nocheck

// DARK MODE
const activateElectrocard=  () => import('libs/animations/src/lib/AnimatedComponent/LottieAnimations/DarkMode/activate-electrocard.json')
const zapExplorerDark = () => import('libs/animations/src/lib/AnimatedComponent/LottieAnimations/DarkMode/exploring-dark.json')
const plugAndChargeDark = () => import('libs/animations/src/lib/AnimatedComponent/LottieAnimations/DarkMode/plug-and-charge-dark.json')
const referAFriendDark = () => import('libs/animations/src/lib/AnimatedComponent/LottieAnimations/DarkMode/refer-a-friend-dark.json')
const zapCelebrationDark = () => import('libs/animations/src/lib/AnimatedComponent/LottieAnimations/DarkMode/zap-celebration-dark.json')
const zapWavingDark = () => import('libs/animations/src/lib/AnimatedComponent/LottieAnimations/DarkMode/zap-waving-dark.json')
const paymentSuccess = () => import('libs/animations/src/lib/AnimatedComponent/LottieAnimations/DarkMode/payment-success.json')
const paymentFail = () => import('libs/animations/src/lib/AnimatedComponent/LottieAnimations/DarkMode/payment-fail.json')
const multipleCards = () => import('libs/animations/src/lib/AnimatedComponent/LottieAnimations/DarkMode/multiple-cards.json')
const loadingBolt = () => import('libs/animations/src/lib/AnimatedComponent/LottieAnimations/DarkMode/loading-bolt.json')
const roadNumberZero = () => import('libs/animations/src/lib/AnimatedComponent/LottieAnimations/DarkMode/road-number-zero.json')

// LIGHT MODE
const constantineBlob = () => import('libs/animations/src/lib/AnimatedComponent/LottieAnimations/LightMode/constantine-blob-look-up.json')
const electroverseLogo = () => import('libs/animations/src/lib/AnimatedComponent/LottieAnimations/LightMode/electroverse-logo.json')
const rocket = () => import('libs/animations/src/lib/AnimatedComponent/LottieAnimations/LightMode/rocket.json')
const spinningWorldMan = () => import('libs/animations/src/lib/AnimatedComponent/LottieAnimations/LightMode/spinning-world-man.json')
const zapExplorer = () => import('libs/animations/src/lib/AnimatedComponent/LottieAnimations/LightMode/exploring.json')
const plugAndCharge = () => import('libs/animations/src/lib/AnimatedComponent/LottieAnimations/LightMode/plug-and-charge.json')
const referAFriend = () => import('libs/animations/src/lib/AnimatedComponent/LottieAnimations/LightMode/refer-a-friend.json')
const zapCelebration = () => import('libs/animations/src/lib/AnimatedComponent/LottieAnimations/LightMode/zap-celebration.json')
const zapWaving = () => import('libs/animations/src/lib/AnimatedComponent/LottieAnimations/LightMode/zap-waving.json')

export const ANIMATIONS = {
  // DARK MODE
  activateElectrocard,
  zapExplorerDark,
  plugAndChargeDark,
  referAFriendDark,
  zapCelebrationDark,
  zapWavingDark,
  paymentSuccess,
  paymentFail,
  multipleCards,
  loadingBolt,
  roadNumberZero,

  // LIGHT MODE
  constantineBlob,
  electroverseLogo,
  rocket,
  spinningWorldMan,
  zapExplorer,
  plugAndCharge,
  referAFriend,
  zapCelebration,
  zapWaving,
}
