import { useCallback, useMemo, createContext, useContext, ReactNode, useState } from 'react'
import { FormikErrors, useFormik } from 'formik'
import { LngLatLike, useMap } from 'react-map-gl'
import {
  PlaceDetails,
  getCameraAnimationOptions,
} from '@electro/consumersite/src/components/Map/helpers'
import { useMarkers, useRoute } from '@electro/consumersite/src/components/Map/hooks'
import {
  INITIAL_VALUES,
  RouteFormFieldsType,
  RouteFormFieldsTypeKeys,
  RouteFormFieldsTypeValues,
  VALIDATION_SCHEMA,
} from '@electro/consumersite/src/components/Map/constants'
import {
  Feature,
  LineString,
  Position,
  bbox,
  featureCollection,
  lineString,
  point,
} from '@turf/turf'
import { convertRoutePlannerFormSubmitToExistingFormat } from '@electro/consumersite/src/components/Map/helpers/convertSidebarDataIntoExistingFormats'

interface State {
  fieldValues: RouteFormFieldsType
  destinations: Record<string, PlaceDetails>
  dashedRouteLine: Feature<LineString, { [name: string]: any }>
  errors: FormikErrors<RouteFormFieldsType>
}

interface Handlers {
  submitForm: () => void
  updateFormField: (field: RouteFormFieldsTypeKeys, value: RouteFormFieldsTypeValues) => void
  updateDestinations: (value: State['destinations']) => void
}

type UseRoutePlannerForm = [state: State, handlers: Handlers]

const UseRoutePlannerFormContext = createContext<UseRoutePlannerForm>(null)

function useRoutePlannerFormProvider(): UseRoutePlannerForm {
  const { baseMap } = useMap()
  const [, { handleNewRouteSubmit }] = useRoute()
  const [, { clearActiveMarker, toggleLocationDetailsPanel }] = useMarkers()

  const [destinations, setDestinations] = useState<State['destinations']>({})
  const [dashedRouteLine, setDashedRouteLine] = useState<State['dashedRouteLine']>()
  const [previousLngLatArray, setPreviousLngLatArray] = useState<LngLatLike[]>([])

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: VALIDATION_SCHEMA,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: (formFields) => {
      const existingFormat = convertRoutePlannerFormSubmitToExistingFormat(formFields)
      handleNewRouteSubmit(existingFormat)
      setTimeout(() => setDashedRouteLine(null), 10000)
    },
  })

  /** Updates a single field on the route planner form  */
  const updateFormField = useCallback(
    (field: RouteFormFieldsTypeKeys, value: RouteFormFieldsTypeValues) =>
      formik.setFieldValue(field, value),
    [formik],
  )

  /** Updates all location fields and performs some map functions (e.g. zoom to area, show dashed route line) */
  const updateDestinations = useCallback(
    (value: State['destinations']) => {
      setDestinations(value)

      const { start, end, ...waypoints } = value
      const waypointPlaceNames = Object.values(waypoints).map(
        ({ name, subtext }) => `${name}, ${subtext}`,
      )

      if (start?.name) updateFormField('startingLocation', `${start.name}, ${start.subtext}`)
      if (end?.name) updateFormField('destination', `${end.name}, ${end.subtext}`)
      updateFormField('waypoints', waypointPlaceNames)

      toggleLocationDetailsPanel({ open: false })
      clearActiveMarker()

      const placeDetailsArray = [start, ...Object.values(waypoints), end] as PlaceDetails[]
      const sanitisedCoordinates = placeDetailsArray.map((p) => p?.coordinates).filter(Boolean)
      const lngLatArray: LngLatLike[] = sanitisedCoordinates.map(({ lng, lat }) => [lng, lat])

      setPreviousLngLatArray(lngLatArray)

      if (JSON.stringify(lngLatArray) !== JSON.stringify(previousLngLatArray)) {
        if (lngLatArray.length > 0) {
          const turfPointArray = lngLatArray.map((lngLat) => point(lngLat as Position))
          const routeFeatureCollection = featureCollection(turfPointArray)
          const routeBBox = bbox(routeFeatureCollection) as [number, number, number, number]

          baseMap.fitBounds(routeBBox, getCameraAnimationOptions({ type: 'fitBounds' }))

          if (lngLatArray.length > 1) setDashedRouteLine(lineString(lngLatArray as Position[]))
          else setDashedRouteLine(null)
        } else setDashedRouteLine(null)
      }
    },
    [updateFormField, toggleLocationDetailsPanel, clearActiveMarker, previousLngLatArray, baseMap],
  )

  const state = useMemo(
    () => ({ fieldValues: formik.values, destinations, dashedRouteLine, errors: formik.errors }),
    [formik.values, destinations, dashedRouteLine, formik.errors],
  )

  const handlers = useMemo(
    () => ({ submitForm: formik.submitForm, updateFormField, updateDestinations }),
    [formik.submitForm, updateFormField, updateDestinations],
  )

  return [state, handlers]
}

interface UseFormProviderProps {
  children: ReactNode | ReactNode[]
}

export const UseRoutePlannerFormProvider = ({ children }: UseFormProviderProps) => {
  const ctx = useRoutePlannerFormProvider()
  return (
    <UseRoutePlannerFormContext.Provider value={ctx}>
      {children}
    </UseRoutePlannerFormContext.Provider>
  )
}

interface UseFormProps {
  /** Allows this hook to exist in a file without its provider.
   *
   * Due to the rules of hooks, the value cannot be changed after component mount */
  skip?: boolean
}

export const useRoutePlannerForm = ({ skip = false }: UseFormProps): UseRoutePlannerForm => {
  if (skip)
    return [null, { submitForm: () => {}, updateFormField: () => {}, updateDestinations: () => {} }]

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const context = useContext(UseRoutePlannerFormContext)
  if (!context)
    throw new Error(
      'useRoutePlannerForm() cannot be used outside of <UseRoutePlannerFormProvider/>',
    )
  return context
}
