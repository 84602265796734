import { Button } from '@electro/shared-ui-components'
import { tw } from '@electro/shared/utils/tailwind-merge'
import useTranslation from 'next-translate/useTranslation'
import { useMapFilters } from '@electro/consumersite/src/components/Map/hooks'
import { MapFiltersEnum } from '@electro/consumersite/src/components/Map/types'
import { GTM } from '@electro/consumersite/src/utils/event-triggers'
import { BoltIcon } from '@electro/consumersite/src/icons'
import { CheckIcon } from '@heroicons/react/16/solid'

const styles = {
  root: 'hidden sm:flex gap-x-2 mx-2 lg:mx-0',
  compatible: {
    root: 'z-30 bg-secondary-dark hover:bg-[#442DC8] py-0.5',
    text: 'text-xs font-semibold tracking-wide mx-1 mb-0',
    icon: tw(
      'flex items-center justify-center rounded-full',
      'border border-secondary text-transparent',
      'w-[18px] h-[18px] [&>svg]:w-3.5 [&>svg]:h-3.5',
    ),
    active: tw(
      'bg-secondary hover:bg-[#5139ed]',
      '[&>span]:text-white [&>span]:bg-base [&>span]:border-transparent',
    ),
  },
  speeds: {
    root: 'z-30 bg-base rounded-full shadow-md flex items-center gap-x-1 py-0.5 px-[3px]',
    label: 'w-[70px] text-xs text-center font-semibold select-none ml-1 mr-[3px] mb-0',
    button: {
      root: 'bg-secondary-dark hover:bg-[#442DC8] my-px py-px',
      text: 'text-xs font-semibold tracking-wide mx-1 mb-0',
      icon: {
        root: 'flex items-center justify-center rounded-full w-4 h-4 [&>svg]:w-3 [&>svg]:h-3',
        rapid: 'bg-base text-[#FFC532]',
        ultra: 'bg-base text-[#FF0074]',
      },
      active: {
        root: '[&>span]:bg-base [&>span]:text-white',
        rapid: 'bg-[#FFC532] hover:bg-[#FFBC13] [&>p]:text-[#180048]',
        ultra: 'bg-[#FF0074] hover:bg-[#E60068]',
      },
    },
  },
}

const { ELECTROVERSE_COMPATIBLE, CHARGE_POINT_SPEEDS } = MapFiltersEnum

/** Provides easy access to the most useful location filters */
export const QuickFilters = ({ hideSpeedFilters = false }: { hideSpeedFilters: boolean }) => {
  const { t } = useTranslation('common')
  const [{ currentFilters }, { updateFilters }] = useMapFilters()

  const [lowSpeedBound, highSpeedBound] = currentFilters[CHARGE_POINT_SPEEDS]

  const slowOrFastActive = lowSpeedBound < 50
  const rapidActive = lowSpeedBound <= 50 && highSpeedBound >= 150
  const ultraActive = lowSpeedBound <= 150 && highSpeedBound === 350

  return (
    <div className={styles.root}>
      <Button
        size="2xs"
        variant="raised"
        className={tw({
          [styles.compatible.root]: true,
          [styles.compatible.active]: currentFilters[ELECTROVERSE_COMPATIBLE],
        })}
        aria-checked={currentFilters[ELECTROVERSE_COMPATIBLE]}
        onClick={() => {
          const updatedState = !currentFilters[ELECTROVERSE_COMPATIBLE]

          updateFilters({ [ELECTROVERSE_COMPATIBLE]: updatedState })
          GTM.toggleQuickFilter({ filter: 'Electroverse Compatible', state: updatedState })
        }}
      >
        <span className={styles.compatible.icon}>
          <CheckIcon />
        </span>
        <p className={styles.compatible.text}>{t('map.filters.electroverse_compatible')}</p>
      </Button>

      {!hideSpeedFilters ? (
        <div className={styles.speeds.root}>
          <p className={styles.speeds.label}>
            {`${lowSpeedBound} - ${highSpeedBound}${highSpeedBound === 350 ? '+' : ''}`}
          </p>

          <Button
            size="2xs"
            variant="raised"
            className={tw({
              [styles.speeds.button.root]: true,
              [styles.speeds.button.active.root]: !slowOrFastActive && rapidActive,
              [styles.speeds.button.active.rapid]: !slowOrFastActive && rapidActive,
            })}
            aria-checked={!slowOrFastActive && rapidActive}
            onClick={() => {
              let updatedState: boolean

              if (slowOrFastActive) {
                // If slider is below rapid speed, set to rapid
                updateFilters({ [CHARGE_POINT_SPEEDS]: [50, 150] })
                updatedState = true
              } else if (rapidActive) {
                // If slider is filtered to rapid, set to ultra or all
                if (ultraActive) updateFilters({ [CHARGE_POINT_SPEEDS]: [150, 350] })
                else updateFilters({ [CHARGE_POINT_SPEEDS]: [0, 350] })
                updatedState = false
              } else {
                // If slider is not filtered to rapid, set to rapid+ultra or rapid only
                if (ultraActive) updateFilters({ [CHARGE_POINT_SPEEDS]: [50, 350] })
                else updateFilters({ [CHARGE_POINT_SPEEDS]: [50, 150] })
                updatedState = true
              }

              GTM.toggleQuickFilter({ filter: 'Rapid', state: updatedState })
            }}
          >
            <span className={tw(styles.speeds.button.icon.root, styles.speeds.button.icon.rapid)}>
              <BoltIcon />
            </span>
            <p className={styles.speeds.button.text}>{t('map.key.speed.rapid')}</p>
          </Button>

          <Button
            size="2xs"
            variant="raised"
            className={tw({
              [styles.speeds.button.root]: true,
              [styles.speeds.button.active.root]: !slowOrFastActive && ultraActive,
              [styles.speeds.button.active.ultra]: !slowOrFastActive && ultraActive,
            })}
            aria-checked={!slowOrFastActive && ultraActive}
            onClick={() => {
              let updatedState: boolean

              if (slowOrFastActive) {
                // If slider is below rapid speed, set to ultra
                updateFilters({ [CHARGE_POINT_SPEEDS]: [150, 350] })
                updatedState = true
              } else if (ultraActive) {
                // If slider is filtered to ultra, set to rapid only or all
                if (rapidActive) updateFilters({ [CHARGE_POINT_SPEEDS]: [50, 150] })
                else updateFilters({ [CHARGE_POINT_SPEEDS]: [0, 350] })
                updatedState = false
              } else {
                // If slider is not filtered to ultra, set to rapid+ultra or ultra only
                if (rapidActive) updateFilters({ [CHARGE_POINT_SPEEDS]: [50, 350] })
                else updateFilters({ [CHARGE_POINT_SPEEDS]: [150, 350] })
                updatedState = true
              }

              GTM.toggleQuickFilter({ filter: 'Ultra', state: updatedState })
            }}
          >
            <span className={tw(styles.speeds.button.icon.root, styles.speeds.button.icon.ultra)}>
              <BoltIcon />
            </span>
            <p className={styles.speeds.button.text}>{t('map.key.speed.ultra')}</p>
          </Button>
        </div>
      ) : null}
    </div>
  )
}
