import { useCallback } from 'react'
import { ChevronLeftIcon } from '@heroicons/react/24/solid'
import useTranslation from 'next-translate/useTranslation'

import { Button } from '@electro/shared-ui-components'
import { useMapModal, useRoute } from '@electro/consumersite/src/components/Map/hooks'

import {
  RouteDetailStagesEnum,
  ModalScreenNames,
} from '@electro/consumersite/src/components/Map/types'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { useRouter } from 'next/router'

interface RouteDetailsPanelActionsProps {
  onShowSteps: (value: boolean) => void
}

export const RouteDetailsPanelActions = ({ onShowSteps }: RouteDetailsPanelActionsProps) => {
  const router = useRouter()
  const { t } = useTranslation('common')
  const [
    { activeRouteId, routes },
    { setRouteDetailsPanelScreen, toggleRouteDetailsPanel, clearRoutes },
  ] = useRoute()
  const [, { setActiveModalScreen }] = useMapModal()

  function handleEditRouteClick() {
    setActiveModalScreen(ModalScreenNames.ROUTE_PLAN_SCREEN)()
    onShowSteps(false)
  }

  function handleClearRoute() {
    toggleRouteDetailsPanel()
    clearRoutes()
    onShowSteps(false)
  }

  const handleSendToGoogleMaps = useCallback(() => {
    const routeSteps = routes.data[activeRouteId].steps
    if (!routeSteps) return
    const origin = `${routeSteps[0].lat},${routeSteps[0].lon}`
    const destination = `${routeSteps[routeSteps.length - 1].lat},${
      routeSteps[routeSteps.length - 1].lon
    }`
    const waypoints =
      routeSteps.length === 2
        ? ''
        : [...routeSteps]
            .slice(1, -1)
            .map((waypoint) => `${waypoint.lat},${waypoint.lon}`)
            .join('|')

    const googleRouteParams = new URLSearchParams({
      origin,
      waypoints,
      destination,
      dir_action: 'navigate',
      travelmode: 'driving',
    })
    window.open(`https://www.google.com/maps/dir/?api=1&${googleRouteParams.toString()}`, '_blank')
  }, [routes, activeRouteId])

  return (
    <div
      className={tw(
        'flex overflow-x-auto gap-2 py-4 px-4 sm:flex-wrap border-b sm:shadow-none sm:border-none relative z-20',
        'shadow-lg shadow-base border-b-secondary',
      )}
    >
      {routes?.routeCount > 1 && (
        <Button
          size="xs"
          variant="outline"
          onClick={() => setRouteDetailsPanelScreen(RouteDetailStagesEnum.SELECTING_ROUTE)}
        >
          <ChevronLeftIcon className="w-4" /> {t('common.button_status.back')}
        </Button>
      )}
      <Button size="xs" className="flex-shrink-0" onClick={handleSendToGoogleMaps}>
        {t('map.route_planner.button.send_to_google_maps')}
      </Button>
      {/* eslint-disable-next-line no-constant-condition */}
      {!router.asPath?.includes('v2') && false ? (
        <Button
          size="xs"
          className="flex-shrink-0"
          variant="outline"
          onClick={handleEditRouteClick}
        >
          {t('map.route_planner.button.edit_route')}
        </Button>
      ) : null}
      <Button size="xs" className="flex-shrink-0" onClick={handleClearRoute} variant="outline">
        {t('map.route_planner.button.clear')}
      </Button>
    </div>
  )
}
